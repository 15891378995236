@import 'theme';

.button {
	align-items: center;
	display: flex;
	padding: 0.5rem 1rem;
	font-weight: 700;
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	border: none;

	line-height: 1rem;
	max-width: 10rem;
	text-overflow: ellipsis;
	overflow: hidden;

	@include focussable;

	color: var(--neo-color-global-content-primary-on-intense);
	background-color: var(--neo-color-global-background-primary-intense-default);

	&:hover {
		background-color: var(--neo-color-global-background-primary-intense-hover);
	}

	&:active {
		background-color: var(--neo-color-global-background-primary-intense-active);
	}
}

.button.call {
	margin-right: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.button.arrow {
	border-left: 1px solid var(--neo-color-global-border-neutral-intense-default);
	padding: 0 11px;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;

	.icon {
		width: 12px;
	}
}

.group {
	display: inline-flex;
}
