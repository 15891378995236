@import 'theme';

.wrapper {
	--backdropDisplay: block;
	--border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	--headerMargin: 3.5rem 3.75rem 0.5rem 3.75rem;
	--contentMargin: 0 3.75rem 3.5rem;
	--contentWidth: auto;
	--centerWidth: 32rem;

	.logo {
		display: var(--logoDisplay, block);
		position: absolute;
		left: 3.5rem;
		top: 3.5rem;
		width: 8rem;
	}

	margin: 0 auto;
	height: 100%;
	color: var(--neo-color-global-content-neutral-intense);

	display: flex;
	align-items: center;
	justify-content: center;

	background-size: cover;

	.backdrop {
		display: var(--backdropDisplay, none);
		position: fixed;
		z-index: -1;
		width: 66rem;
		height: 67.5rem;

		background: url('img/bg-fade.svg') no-repeat center center;
		animation:
			opacity 0.5s linear 0.5s 1 forwards,
			waber 7.5s linear 1s infinite alternate;
		transform-box: fill-box;
		transform-origin: center;
		opacity: 0;

		@media (prefers-reduced-motion) {
			animation: none;
			opacity: 1;
		}

		// Firefox has problems with svg gradients in background-image. we use a png instead
		@-moz-document url-prefix() {
			background: url('img/bg-fade.png') no-repeat center center;
		}

		@keyframes opacity {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes waber {
			0% {
				transform: scale(1) skew(0deg) rotate(0deg);
			}
			100% {
				transform: scale(0.75) skew(10deg, 10deg) rotate(15deg);
			}
		}
	}

	.center {
		display: flex;
		flex-direction: column;
		height: var(--centerHeight);
		width: var(--centerWidth);

		background: var(--neo-color-global-surface-dialog);
		border-radius: 0.5rem;
		border: var(--border, 'none');

		.content {
			margin: var(--contentMargin);
			width: var(--contentWidth);

			.title {
				padding-top: 2rem;
				padding-left: 3rem;
				padding-right: 3rem;
				margin: 1rem 0 1.5rem;
				max-width: 100%;

				font-size: 1.5rem;
				font-weight: 700;

				text-align: center;
			}

			p {
				font-size: 1rem;
				line-height: 1.5rem;
				margin-top: 0;
				text-align: center;
			}

			& > :last-child {
				margin-top: 1rem;
			}
		}
	}
}

@media (max-width: $breakpoint-width-md) {
	.wrapper {
		--logoDisplay: none;
		--contentMargin: 0 auto 3.5rem;
		--contentWidth: 20rem;
		--centerWidth: 100%;
		--centerHeight: 100%;
		--backdropDisplay: none;
	}
}
