.buttonGroup {
	height: 100%;
	display: inline-flex;

	button:not(:last-child) {
		margin-right: 0;

		border-right-width: 1px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	button:not(:first-child) {
		margin-left: 0;
		border-left-width: 0;

		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
