/* Px Grotesk | Legal Disclaimer: These Fonts are licensed only for use on these domains and their subdomains: 'sipgate.de, sipgate.co.uk'. It is illegal to download or use them on other websites. While the @font-face statements below may be modified by the client, this disclaimer may not be removed. optimo.ch */

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 300;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-light.woff2') format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 300;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-light-italic.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 400;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 400;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-italic.woff2') format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 700;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-bold.woff2') format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk';
	font-weight: 700;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk/px-grotesk-bold-italic.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 300;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-light.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 300;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-light-italic.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 400;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-regular.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 400;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-italic.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 700;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-bold.woff2')
		format('woff2');
}

@font-face {
	font-family: 'PX-Grotesk-Mono';
	font-weight: 700;
	font-style: italic;
	src: url('https://static.sipgate.com/fonts/px-grotesk-mono/px-grotesk-mono-bold-italic.woff2')
		format('woff2');
}

@font-face {
	font-family: 'DIN-Next';
	font-weight: 300;
	font-style: normal;
	src: url('https://static.sipgate.com/fonts/din-next/e492fdb2-9629-4064-a3cd-1233d2a65d11.woff2')
		format('woff2');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-display: swap;
	src: url('https://static.sipgate.com/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}
