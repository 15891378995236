.container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: stretch;
}

.container.column {
	height: 100%;
	flex-direction: column;
}

.container.vertical,
.container.column.horizontal {
	align-items: center;
}

.container.horizontal,
.container.column.vertical {
	justify-content: center;
}
