@import 'theme';

.button {
	align-items: center;
	display: flex;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	background-color: var(--neo-color-global-background-neutral-soft-default);
	font-weight: 700;
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	color: var(--neo-color-global-content-neutral-intense);

	line-height: 1rem;
	text-overflow: ellipsis;
	overflow: hidden;

	&.gray {
		background-color: var(--neo-color-global-background-neutral-soft-default);
	}

	@include focussable;

	&:focus-visible {
		border-color: var(--neo-color-global-border-static-focus);
	}

	@media (pointer: coarse) {
		padding: 0.75rem 1.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	&.primary {
		color: var(--neo-color-global-content-primary-on-intense);
		background-color: var(--neo-color-global-background-primary-intense-default);

		&:hover {
			color: var(--neo-color-global-content-primary-on-intense);
			background-color: var(--neo-color-global-background-primary-intense-hover);
		}

		&:active {
			color: var(--neo-color-global-content-primary-on-intense);
			background-color: var(--neo-color-global-background-primary-intense-active);
		}

		&.gray {
			color: var(--neo-color-global-content-neutral-intense);
			background-color: var(--neo-color-global-background-neutral-soft-default);
		}
	}

	&:hover {
		color: var(--neo-color-global-content-neutral-intense);
		background-color: var(--neo-color-global-background-primary-soft-hover);

		&.gray {
			background-color: var(--neo-color-global-background-neutral-soft-hover);
		}
	}

	&:active {
		background-color: var(--neo-color-global-background-primary-soft-active);

		&.gray {
			background-color: var(--neo-color-global-background-neutral-soft-active);
		}
	}

	.icon {
		width: 10px;
		height: 10px;
		margin-left: 6px;

		@include icon('../../media/icons/icon-triangle_down-dark-solid-default-16.svg');

		@media (pointer: coarse) {
			margin-left: 12px;
		}
	}
}
