:global(.group):hover .backgroundHover {
	background: radial-gradient(
		50% 50% at 50% 50%,
		var(--neo-color-global-content-primary-moderate) 37.45%,
		var(--neo-color-global-background-neutral-soft-default) 37.45%
	);
}

:global(.group):active .backgroundActive {
	background: radial-gradient(
		50% 50% at 50% 50%,
		var(--neo-color-global-content-primary-intense) 37.45%,
		var(--neo-color-global-background-neutral-soft-default) 37.45%
	);
}
