@keyframes grow-shadow {
	from {
		transform: scale(0.1);
	}
	to {
		transform: scale(1);
	}
}

@keyframes show-stars {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes move-from-left {
	0% {
		transform: rotate(-5deg) scale(0.1);
	}
	5% {
		transform: rotate(-5deg) scale(0.1);
		opacity: 1;
	}
	70% {
		transform: rotate(-5deg) scale(1);
		opacity: 1;
	}
	100% {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}

@keyframes move-from-right {
	0% {
		transform: rotate(5deg) scale(0.1);
	}
	5% {
		transform: rotate(5deg) scale(0.1);
		opacity: 1;
	}
	70% {
		transform: rotate(5deg) scale(1);
		opacity: 1;
	}
	100% {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}

@keyframes move-up {
	0% {
		transform: translateY(10px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes row {
	0% {
		transform: scale(0.1);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes grow {
	0% {
		opacity: 0;
		transform: scale(0.1);
	}
	50% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes move-down {
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes refresh {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	20% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	70% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loading {
	0%,
	80%,
	100% {
		transform: scale(0);
		opacity: 0;
	}
	40% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes ring {
	0% {
		opacity: 1;
		transform: translate(5px, -5px);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate(0px, 0px);
	}
}

@keyframes text {
	to {
		stroke-dashoffset: 0;
	}
}
