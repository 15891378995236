@import 'theme';

.borderedContainer {
	border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	background-color: var(--neo-color-global-surface-section);
	border-radius: 0.5rem;
	overflow: hidden;
	padding: 1.5rem;

	@include isMinMediumDevice {
		padding: 2rem;
	}
}
