.wrapper {
	--overlap-distance: 0.125rem;
	--state-offset: 0px;

	position: relative;
	flex-shrink: 0;
	margin: 0 auto;
	user-select: none;

	width: calc(var(--circle-radius) * 2);
	height: calc(var(--circle-radius) * 2);
}

.wrapper .image {
	object-fit: cover;
	user-select: none;

	width: calc(var(--circle-radius) * 2);
	height: calc(var(--circle-radius) * 2);
}

.visibleMask {
	r: var(--circle-radius);
	cx: var(--circle-radius);
	cy: var(--circle-radius);
}

.overlapMask {
	r: calc(var(--circle-radius) + var(--overlap-distance));
	cx: calc(-1 * var(--circle-radius) + var(--circle-overlap));
	cy: var(--circle-radius);
}

.stateMask {
	r: calc(var(--state-radius) + var(--state-padding));
	cx: calc(var(--circle-radius) * 2 - var(--state-radius) - var(--state-offset));
	cy: calc(var(--circle-radius) * 2 - var(--state-radius) - var(--state-offset));
}

.state {
	width: calc(var(--state-radius) * 2);
	height: calc(var(--state-radius) * 2);
	right: var(--state-offset);
	bottom: var(--state-offset);
	display: block;
	border: none;
	position: absolute;
}

.text {
	font-weight: 700;
}

/* ####################### Sizes ####################### */

.wrapper.small {
	--circle-radius: 0.75rem;
	--state-radius: 0.25rem;
	--state-padding: 0.0625rem;

	@apply text-[0.625rem];
	@apply leading-14;
}

.wrapper.medium {
	--circle-radius: 1rem;
	--circle-overlap: 0.25rem;
	--state-radius: 0.3125rem;
	--state-padding: 0.125rem;

	@apply text-xs;
	@apply leading-14;
}

.wrapper.medium .remainingCount {
	@apply text-xs;
	@apply leading-14;
}

.wrapper.large {
	--circle-radius: 1.25rem;
	--circle-overlap: 0.375rem;
	--state-radius: 0.375rem;
	--state-padding: 0.125rem;

	@apply text-base;
	@apply leading-20;
}

.wrapper.large .remainingCount {
	@apply text-sm;
	@apply leading-16;
}

.wrapper.xlarge {
	--circle-radius: 1.5rem;
	--state-radius: 0.5rem;
	--state-padding: 0.125rem;

	@apply text-xl;
	@apply leading-24;
}

.wrapper.biggest {
	--circle-radius: 3.25rem;
	--state-radius: 0.75rem;
	--state-padding: 0.25rem;
	--state-offset: 0.25rem;

	@apply text-5xl;
	@apply leading-40;
}
