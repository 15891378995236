@import 'theme';

.dropOut {
	display: none;
	position: absolute;
	min-width: 13.5rem;
	max-width: 18rem;
	padding: 0;
	margin: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	top: 4rem;

	border-radius: 0.5rem;
	overflow: hidden;
	background: var(--neo-color-global-surface-menu);

	box-shadow: 0 4px 24px var(--neo-color-global-shadow-default);
	z-index: 1000;

	&.open {
		display: block;
	}

	ul {
		top: 4rem;
		margin: 0;
		padding: 0;

		a {
			padding: 0.75rem 1rem;
			text-decoration: none;
			width: 100%;
			display: block;
			outline: 0;

			color: var(--neo-color-global-content-neutral-intense);

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			}

			&:hover {
				background: var(--neo-color-global-background-primary-soft-hover);
				color: var(--neo-color-global-content-primary-intense);
			}

			&:active {
				background: var(--neo-color-global-background-primary-soft-active);
				color: var(--neo-color-global-content-primary-intense);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			}
		}

		li {
			list-style: none;
			outline: 0;
			width: 100%;

			hr {
				border-top: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
				margin: 0;
			}
		}
	}
}

.divider {
	padding: 0.25rem 0.5rem;
}
