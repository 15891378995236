@import 'theme';

.filterlist {
	list-style: none;
	padding: 0;
	margin: 0.75rem 1rem;

	hr {
		border: none;
		border-top: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
		margin: 0.75rem 0;
	}
}

.twoColumns .filterlist {
	columns: 2;
	margin: 0;
}
