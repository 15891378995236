@import 'theme';

.button {
	margin: 0;
	border: 1px solid var(--neo-color-global-border-neutral-intense-default);
	border-radius: 2px;
	padding: 7px 10px;
	background-color: var(--neo-color-web-app-surface-app);
	font-weight: 700;
	transition-property: background-color, border-color, color, fill, stroke;
	transition-duration: 200ms;
	transition-timing-function: linear;
	user-select: none;

	@include focussable;
}
.button:hover {
	background-color: var(--neo-color-global-background-primary-soft-hover);
	border: 1px solid var(--neo-color-global-border-primary-intense-hover);
}

.button:active {
	background-color: var(--neo-color-global-background-primary-soft-active);
	border: 1px solid var(--neo-color-global-border-primary-intense-active);
}

.button:disabled {
	color: var(--neo-color-global-content-neutral-disabled);
	background-color: var(--neo-color-web-app-surface-app);
}
