@import 'theme';

.player {
	display: flex;
	border: 1px solid var(--neo-color-global-border-neutral-moderate-default);
	border-radius: 4px;
	align-items: center;
}

.player audio {
	display: none;
}

.time,
.button,
.progress {
	padding: 8px 16px;
}

.button {
	border: none;
	padding: 8px;
	background: none;
	border-right: 1px solid var(--neo-color-global-border-neutral-moderate-default);

	@include focussable;
}

.button img {
	width: 18px;
	display: block;
}

.progress {
	cursor: pointer;
	flex-grow: 1;
}

.progressBackground {
	height: 4px;
	border-radius: 2px;
	background-color: var(--neo-color-global-background-neutral-moderate-default);
	overflow: hidden;
}

.progressContent {
	height: 4px;
	background-color: var(--neo-color-global-background-primary-intense-default);
	transition: width 250ms linear;
}

.time {
	width: 56px;
	padding-left: 0;
	text-align: right;
}
