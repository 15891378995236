@import 'theme';

.header {
	position: sticky;
	top: 0;
	background: var(--neo-color-web-app-surface-header);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	z-index: 1000;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
		opacity, box-shadow, transform, filter, backdrop-filter;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;

	&.scrolled {
		box-shadow: 0 0.25rem 0.5rem 0 var(--neo-color-global-shadow-default);
	}

	@media (min-width: $breakpoint-width-xl) {
		background: var(--neo-color-web-app-surface-app);
		padding: 1rem 5rem 1rem 5rem;
		flex-wrap: nowrap;
	}

	&:last-child {
		margin-left: auto;
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
		padding: 1rem;
		& > svg {
			margin: 0;
		}

		@media (min-width: $breakpoint-width-xl) {
			gap: 1rem;
			padding: 0;
		}

		@media (max-width: calc($breakpoint-width-md - 1px)) {
			& > :nth-child(2),
			& > :nth-child(3) {
				display: none;
			}

			& > :last-child {
				display: flex;
			}
		}
	}

	.left {
		display: flex;
		align-items: center;
		padding-left: 0.5rem;
		gap: 0.75rem;

		@media (min-width: $breakpoint-width-xl) {
			display: none;
		}
	}

	& > :nth-child(2) {
		background: var(--neo-color-web-app-surface-app);
		order: 4;
		width: 100%;

		padding: 0.5rem 1rem;

		@media (min-width: $breakpoint-width-xl) {
			background: none;
			order: 0;
			width: auto;
			padding: 0;
		}
	}

	.areaswitch a {
		background-color: var(--neo-color-web-app-component-area-switch-background-inverted-default);
		color: var(--neo-color-web-app-component-area-switch-content-inverted-default);

		&:hover {
			background-color: var(--neo-color-web-app-component-area-switch-background-inverted-hover);
		}

		&:active {
			background-color: var(--neo-color-web-app-component-area-switch-background-inverted-active);
		}
	}

	@media (min-width: $breakpoint-width-xl) {
		.areaswitch a {
			background-color: var(--neo-color-web-app-component-area-switch-background-default);
			color: var(--neo-color-web-app-component-area-switch-content-default);

			&:hover {
				background-color: var(--neo-color-web-app-component-area-switch-background-hover);
			}
			&:active {
				background-color: var(--neo-color-web-app-component-area-switch-background-active);
			}
		}
	}

	.logo {
		width: 8rem;
		height: 2rem;
		margin-right: 0.5rem;
		display: flex;
		object-position: left;
		object-fit: contain;
	}

	&:not(.withCustomLogo) {
		.logo {
			@media (max-width: calc($breakpoint-width-md - 1px)) {
				width: 1.56rem;
				object-fit: cover;
			}
		}
	}

	&.withCustomLogo {
		.logo {
			@media (max-width: calc($breakpoint-width-lg - 1px)) {
				width: 1.56rem;
				object-fit: cover;
			}
		}
	}

	&.withNqNupsi {
		@media (max-width: calc($breakpoint-width-md - 1px)) {
			.right {
				& > :nth-child(1),
				& > :nth-child(4) {
					display: none;
				}

				& > :nth-child(2),
				& > :last-child {
					display: flex;
				}
			}
		}
	}

	.helpdesk {
		filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg)
			brightness(104%) contrast(104%);

		@media (min-width: $breakpoint-width-xl) {
			filter: brightness(0) saturate(100%) invert(16%) sepia(0%) saturate(1%) hue-rotate(244deg)
				brightness(101%) contrast(88%);
		}
	}
}
