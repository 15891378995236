.enter {
	@apply opacity-0;
	@apply block;
	@apply transition-opacity;
	@apply duration-150;
	@apply ease-in-out;
}

.enter.enterActive {
	@apply opacity-100;
}

.exit {
	@apply opacity-100;
	@apply block;
	@apply transition-opacity;
	@apply duration-150;
	@apply ease-in-out;
}

.exit.exitActive {
	@apply opacity-0;
}
