@import 'theme';

.tooltip {
	z-index: $layer-tooltip;
	visibility: hidden;
	opacity: 0;

	box-shadow:
		var(--neo-color-global-shadow-subtle) 0 1px 6px,
		var(--neo-color-global-shadow-subtle) 0 1px 4px;

	font-size: 0.75rem;

	.tooltipBubble {
		padding: 8px;
		border-radius: 4px;
		text-align: center;
		line-height: 1.2;
		background-color: var(--neo-color-global-surface-menu);
		color: var(--neo-color-global-content-neutral-intense);
	}

	.arrow {
		position: absolute;

		&.bottom {
			top: -6px;
			border-right: 6px solid var(--neo-color-global-background-static-transparent);
			border-bottom: 6px solid var(--neo-color-global-surface-menu);
			border-left: 6px solid var(--neo-color-global-background-static-transparent);
		}

		&.top {
			bottom: -6px;
			border-right: 6px solid var(--neo-color-global-background-static-transparent);
			border-top: 6px solid var(--neo-color-global-surface-menu);
			border-left: 6px solid var(--neo-color-global-background-static-transparent);
		}

		&.left {
			top: calc(50% - 6px);
			right: -6px;
			border-bottom: 6px solid var(--neo-color-global-background-static-transparent);
			border-left: 6px solid var(--neo-color-global-surface-menu);
			border-top: 6px solid var(--neo-color-global-background-static-transparent);
		}

		&.right {
			top: calc(50% - 6px);
			left: -6px;
			border-top: 6px solid var(--neo-color-global-background-static-transparent);
			border-right: 6px solid var(--neo-color-global-surface-menu);
			border-bottom: 6px solid var(--neo-color-global-background-static-transparent);
		}

		&::before {
			content: '';
			height: 8px;
			width: 8px;
			transform: rotate(45deg);
			position: absolute;
			left: 1.76px;
			top: -4.34px;
		}
	}

	&.isOpen {
		visibility: visible;
		opacity: 1;
	}
}
