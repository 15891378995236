.headline {
	margin: 2rem 0 0.25rem 0.125rem;
	font-size: 0.75rem;
	font-weight: normal;
	color: var(--neo-color-global-content-neutral-intense);
	line-height: 1;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 0 0 1rem;
	background: var(--neo-color-global-surface-section);
	border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	border-radius: 0.5rem;

	&.fancy {
		border: double 0.125rem transparent;
		background-image: linear-gradient(
				to bottom,
				var(--neo-color-global-surface-section),
				var(--neo-color-global-surface-section)
			),
			linear-gradient(
				90deg,
				var(--neo-color-global-gradient-2-accents-0) 0%,
				var(--neo-color-global-gradient-2-accents-100) 100%
			);
		background-origin: border-box;
		background-position: center;
		background-clip: content-box, border-box;
		background-size: 300% 300%;
		padding: 0;

		& > * {
			padding-left: 1rem;
		}
	}
}
