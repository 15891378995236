@import 'theme';

.bold {
	font-weight: 700;
}

.actionButtons {
	display: flex;
	flex-wrap: wrap;

	button,
	a {
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		white-space: nowrap;
	}
}

.preview {
	margin-bottom: 0.25rem;
}

.preview a {
	@include focussable;
}

.preview img {
	width: 4.375rem;
	margin-right: 0.5rem;
	border: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
}
