@import 'theme';

.startCodeButtonContainer {
	display: flex;
	container-type: inline-size;

	.startCodeButton {
		display: none;
		opacity: 0;
		box-shadow: var(--neo-color-global-border-info-moderate-default) 0 0 0 1px inset;
		border-radius: 0.5rem;
		width: 100%;
		height: 3.5rem;

		box-sizing: border-box;
		padding: 0.5rem;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;
		background-color: var(--neo-color-global-background-info-soft-default);

		@include focussable;

		&:hover {
			background-color: var(--neo-color-global-background-info-soft-hover);
		}

		&:active {
			background-color: var(--neo-color-global-background-info-soft-active);
		}
	}

	p {
		margin: 0;
		color: var(--neo-color-global-content-info-intense);
		text-align: center;

		&.enterCodeText {
			font-size: 1rem;
			font-weight: 700;
		}

		&.completeVerificationText {
			font-size: 0.75rem;
		}
	}

	@container (min-width: 16rem) {
		.startCodeButton {
			display: block;
			animation: fadeIn 0.3s ease-in forwards;
			margin-top: 1rem;
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
