@import 'theme';

.filterGroup {
	display: flex;
	flex-grow: 1;
	white-space: nowrap;
	align-items: center;

	> * {
		display: none;

		@media (min-width: $breakpoint-width-md) {
			display: block;
			margin-right: 0.5rem;
		}
	}

	> :nth-child(n + 4) {
		display: none;

		@media (min-width: $breakpoint-width-2xl) {
			display: block;
		}
	}

	.allFilters {
		display: inline-block;
		margin-right: 0.5rem;

		@media (min-width: $breakpoint-width-md) {
			display: none;
		}
	}

	.moreFilters {
		display: none;
	}

	@media (min-width: $breakpoint-width-md) {
		.moreFilters {
			display: inline-block;
		}
	}

	@media (min-width: $breakpoint-width-2xl) {
		.moreFilters {
			display: none;
		}
	}

	.reset {
		display: inline-block;

		@media (min-width: $breakpoint-width-2xl) {
			display: none;
		}
	}
}

.slideInFilters {
	flex-grow: 1;
	overflow: auto;
	overflow-x: hidden;
	background: var(--neo-color-global-surface-menu);

	@media (min-width: $breakpoint-width-lg) {
		.visibilitySwitchForFilters {
			display: none;
		}
	}
}

.filterSlideIn {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: absolute;
}

.mobileCloseButton {
	display: block;
	border: none;

	flex-shrink: 0;
	color: var(--neo-color-global-content-primary-on-intense);
	height: 3em;
	background: var(--neo-color-global-background-primary-intense-default);

	.spinner {
		height: 1.5em;
		width: 1.5em;
		margin: 0 auto;

		circle {
			stroke: var(--neo-color-global-content-primary-on-intense);
		}
	}

	@media (min-width: $breakpoint-width-md) {
		display: none;
	}
}
