@import 'theme';

.dialogPageWrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	@include isMinMediumDevice {
		position: relative;
	}
}
