.enter {
	opacity: 0;
	transition: opacity 200ms ease;
}

.enter.enterActive {
	opacity: 1;
}

.exit {
	opacity: 1;
	display: block;
	transition: opacity 200ms ease;
}

.exit.exitActive {
	opacity: 0;
}
