@import 'theme';

.roundProgressIndicator {
	--transition-duration: 0.075s;

	.circle {
		fill: none;
		stroke-width: 0.75rem;
		stroke-linecap: round;
		transform: rotate(-90deg);
		transform-origin: 50%;
		transition: stroke-dashoffset 0.3s ease-in-out;
	}

	.background {
		stroke: var(--neo-color-global-background-neutral-moderate-default);
		stroke-width: 0.75rem;
		fill: none;
	}

	text {
		font: $font-px-grotesk;
		fill: var(--neo-color-global-content-neutral-intense);
		font-size: 1.75rem;
		font-style: normal;
		font-weight: 700;
		line-height: 2rem;
		dominant-baseline: middle;
		text-anchor: middle;
	}

	.value {
		transition-delay: 0s;
		transition-duration: var(--transition-duration);
		transition-property: font-size, transform;
		transition-timing-function: linear;
	}

	.unit {
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1.5rem;

		transition-delay: var(--transition-duration);
		transition-duration: var(--transition-duration);
		transition-property: opacity;
		transition-timing-function: linear;
	}
}

.roundProgressIndicator.small {
	.value {
		font-size: 3rem;
		transform: translateY(0.75rem);
		transition-delay: var(--transition-duration);
	}

	.unit {
		opacity: 0;
		transition-delay: 0s;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.roundProgressIndicator {
		--transition-duration: 0s;
	}
}
