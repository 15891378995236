.logo:hover::after,
.logo:focus-visible::after {
	content: '';
	background-image: url('../../../assets/icons/panda-icons/svgs/icon-camera-light-line-default-24.svg');
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: 4rem;
	background-repeat: no-repeat;
	background-position: center;
}
