@import 'theme';

.preview {
	--transition-duration: 0.15s;

	position: relative;
	display: flex;
	flex-direction: column;

	.headline {
		color: var(--neo-color-global-content-neutral-intense);
		font-feature-settings: 'calt' off;
		font-family: $font-px-grotesk;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	button {
		display: block;
		align-items: center;
		padding: 0;
		margin: 0;

		height: auto;
		width: 100%;
		box-sizing: border-box;

		background: none;
		border: none;
		cursor: default;
		outline: inherit;
		text-align: center;

		font-family: $font-px-grotesk;
		font-size: 1rem;
		font-style: normal;
		font-weight: 700;
		text-transform: capitalize;

		border-radius: 0.5rem;
		color: var(--neo-color-global-content-neutral-moderate);
		text-decoration: none;
	}

	.previewContent {
		display: flex;
		padding: 1rem 1rem 1rem 0.5rem;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		border-radius: 0.5rem;
		background: var(--neo-color-global-background-neutral-soft-default);
		outline: none;

		transition-property: background-color, height, width;
		transition-duration: var(--transition-duration);
		transition-timing-function: linear;

		.indicator {
			aspect-ratio: 1;
			height: 6rem;
			width: 6rem;

			transition-property: height, width;
			transition-duration: var(--transition-duration);
			transition-timing-function: linear;
		}

		& > a,
		& > p {
			display: none;
		}

		.content {
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.25rem;

			transition-property: visibility, opacity;
			transition-duration: var(--transition-duration);
			transition-timing-function: linear;

			& > p {
				margin: 0 0 0.5rem;
				max-height: 3.75rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			& > a {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	@media (max-width: (calc($breakpoint-width-xl - 1px))) {
		.previewContent {
			padding: 0.5rem;
		}
	}

	@media (max-width: $breakpoint-width-sm) {
		.previewContent {
			flex-wrap: wrap;

			.content {
				display: none;
			}

			& > a {
				display: inline-flex;
			}

			& > p {
				display: block;
			}

			& > p {
				flex: 1;
			}
		}
	}

	&.small {
		flex-direction: row;

		max-height: 3rem;
		max-width: 3rem;

		.headline {
			display: none;
		}

		.previewContent {
			background: none;
			padding: 0;

			align-items: flex-end;

			.indicator {
				height: 3rem;
				width: 3rem;
			}

			.content {
				position: absolute;

				display: flex;
				flex-direction: column;
				border-radius: 0.5rem;

				padding: 1rem;
				left: 4.25rem;
				width: 12.5rem;
				background: var(--neo-color-global-surface-menu);

				.headline {
					font-size: 1rem;
					line-height: 1rem;
				}

				&:not(.open) {
					display: none;
				}
			}
		}

		button {
			&:hover {
				background: var(--neo-color-global-background-neutral-soft-default);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			}
		}

		&.open {
			position: relative;
		}
	}

	&.medium {
		.indicator,
		.headline {
			display: none;
		}

		.previewContent {
			p {
				margin: 0.5rem 0 0.5rem 0;
			}
		}
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.preview {
		--transition-duration: 0s;
	}
}
