@import 'theme';

.overlay {
	display: block;
	background-color: var(--neo-color-global-surface-overlay);
	backdrop-filter: blur(2px);
	pointer-events: auto;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1449;

	@media (min-width: $breakpoint-width-xl) {
		display: none;
	}

	&.closed {
		display: none;
	}
}

.navigation {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	color: var(--neo-color-global-content-neutral-moderate);
	min-width: 20rem;
	width: 20rem;
	background-color: var(--neo-color-web-app-surface-navigation);

	padding: 1rem;
	z-index: 1449;

	transition-property: min-width, width, padding-right;
	transition-timing-function: ease-in-out;
	transition-duration: 0.5s;

	@media (max-width: calc($breakpoint-width-xl - 1px)) {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1450;
	}

	nav {
		display: flex;
		flex-direction: column;

		flex: 1;
		overflow: hidden;

		& > ul {
			margin: 0;

			:first-child > a > span {
				font-weight: 700;
			}

			overflow-x: hidden;
			overflow-y: auto;

			scrollbar-width: auto;
			scrollbar-color: var(--neo-color-global-background-neutral-moderate-default)
				var(--neo-color-global-background-static-transparent);

			&::-webkit-scrollbar {
				background: var(--neo-color-global-background-static-transparent);
				width: 0.5rem;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--neo-color-global-background-neutral-moderate-default);
				border-radius: 0.25rem;
			}

			position: relative;

			&.scrolled {
				&:before {
					visibility: visible;
				}
			}

			&:before,
			&:after {
				position: sticky;
				content: '';
				background: linear-gradient(
					360deg,
					var(--neo-color-global-background-static-transparent) 0%,
					var(--neo-color-web-app-surface-navigation) 100%
				);
				width: 100%;
				display: block;
				min-height: 1rem;
			}

			&:before {
				top: 0rem;
				visibility: hidden;
			}

			&:after {
				bottom: 0rem;
				transform: rotate(180deg);
			}
		}

		.communicationButtons {
			margin: 1rem 0 0 0;

			div {
				margin: 0;
				padding: 0;
				gap: 0.25rem;

				button {
					font-size: 0.875rem;
					font-weight: 700;
					line-height: 1.5rem;
					margin: 0;
					padding: 0.5rem;
				}
			}
		}

		& ul {
			padding: 0;
			flex: 1;
			display: flex;
			flex-direction: column;

			li {
				margin: 0.125rem 0;
				list-style: none;
			}
		}
	}

	&.inTransition {
		nav {
			.header {
				button,
				.toggleButton {
					display: none;
				}
			}
		}

		& > div:last-child {
			padding-right: 0;
		}
	}

	.header {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1.25rem;
		justify-content: space-between;
		align-items: center;

		padding: 0.25rem 0 0.125rem 0;
		@media (min-width: $breakpoint-width-md) {
			padding: 0.25rem 0 1.25rem 0;
			gap: 1rem;
			flex-wrap: nowrap;
		}
		max-width: 100%;

		overflow: hidden;

		& > a {
			height: 3.5rem;
			border-radius: 0.5rem;
		}

		a:has(> img) {
			height: 2rem;
		}

		.logo {
			flex: 1;
			height: 2rem;
			padding-left: 0.75rem;
			object-fit: contain;
			object-position: left;
		}

		button,
		.toggleButton {
			position: relative;
			display: flex;
			opacity: 1;
			width: 2rem;
			height: 2rem;
			padding: 0.25rem;
			justify-content: center;
			align-items: center;

			border-radius: 0.5rem;

			background: var(--neo-color-global-background-neutral-soft-default);
			border: none;
			cursor: pointer;
			outline: inherit;
			text-align: left;
			transition-property: opacity, color, background-color, border-color, text-decoration-color,
				fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
			transition-duration: 150ms;
			transition-timing-function: ease-in-out;

			&:hover {
				background-color: var(--neo-color-global-background-neutral-soft-hover);
			}

			&:active {
				background-color: var(--neo-color-global-background-neutral-soft-active);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			}

			/* Expand clickable area on touch devices */
			@media (pointer: coarse) {
				&::after {
					content: ' ';
					position: absolute;
					top: -0.5rem;
					right: -0.5rem;
					bottom: -0.5rem;
					left: -0.5rem;
				}
			}
		}
	}
}

.closed {
	padding: 1rem;
	min-width: 5rem;
	width: 5rem;
	overflow: visible;

	& > div:last-child {
		padding-right: 0;
	}

	.header {
		flex-direction: row;
		max-width: 2.5rem;

		.logo {
			height: 2rem;
		}

		button,
		.toggleButton {
			opacity: 0;
			position: fixed;
			left: 4rem;
			rotate: 180deg;
			height: 2rem;
			width: 2rem;
		}
	}

	nav {
		ul {
			margin: 0;
			padding: 0;
			scrollbar-gutter: unset;
			position: relative;
		}
	}

	& > * {
		padding: 0;
	}

	& > ul {
		scrollbar-gutter: unset;
		margin: 0;
	}

	&:hover {
		.header {
			& > button,
			& > .toggleButton {
				opacity: 1;
			}
		}
	}

	@media (max-width: calc($breakpoint-width-xl - 1px)) {
		transition: all 0s;
		display: none;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.navigation {
		transition: all 0s;

		&.inTransition {
			nav {
				.header {
					button,
					.toggleButton {
						opacity: 1;
					}
				}
			}
		}
	}
}

div.mobileSectionSwitcher {
	margin: 0;
	@media (min-width: calc($breakpoint-width-md)) {
		display: none;
	}

	a {
		background-color: var(--neo-color-web-app-component-area-switch-background-default);
		color: var(--neo-color-web-app-component-area-switch-content-default);

		&:hover {
			background-color: var(--neo-color-web-app-component-area-switch-background-hover);
			color: var(--neo-color-web-app-component-area-switch-content-default);
		}

		&:active {
			background-color: var(--neo-color-web-app-component-area-switch-background-active);
			color: var(--neo-color-web-app-component-area-switch-content-default);
		}
	}
}

div.mobileSectionSwitcherInverted {
	margin: 0;
	@media (min-width: calc($breakpoint-width-md)) {
		display: none;
	}

	a {
		background-color: var(--neo-color-web-app-component-area-switch-background-inverted-default);
		color: var(--neo-color-web-app-component-area-switch-content-inverted-default);

		&:hover {
			background-color: var(--neo-color-web-app-component-area-switch-background-inverted-hover);
			color: var(--neo-color-web-app-component-area-switch-content-inverted-default);
		}

		&:active {
			background-color: var(--neo-color-web-app-component-area-switch-background-inverted-active);
			color: var(--neo-color-web-app-component-area-switch-content-inverted-default);
		}
	}
}

.widgets {
	margin: 0;
	@media (min-width: calc($breakpoint-width-md)) and (max-width: calc($breakpoint-width-lg)) {
		margin: 0;
	}

	& > h1 {
		display: none;
	}
}
