@import 'theme';

.actions {
	flex-grow: 1;
	text-align: right;
	white-space: nowrap;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;

	.mobileMenu {
		display: none;
	}

	.desktopMenu {
		display: flex;
	}

	@media (max-width: $breakpoint-width-md) {
		.mobileMenu {
			display: inline-flex;
		}

		.desktopMenu {
			display: none;
		}
	}

	@media (pointer: coarse) and (max-width: $breakpoint-width-xl) {
		.mobileMenu {
			display: inline-flex;
		}

		.desktopMenu {
			display: none;
		}
	}
}

.mobileMenu > *,
.desktopMenu > *,
.actions > * {
	margin-left: 4px;
	margin-right: 4px;
}
