@import 'theme';

.tooltip {
	background: var(--neo-color-global-surface-dialog);
	color: var(--neo-color-global-content-neutral-intense);
	max-width: 24rem;
	border-radius: 0.5rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.button {
		@include focussable;

		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 0.15s;
		color: var(--neo-color-global-content-neutral-intense);
		background-color: var(--neo-color-global-background-static-transparent);
		border-radius: 0.25rem;
		margin: 0;

		font-weight: 700;
		line-height: 1rem;
		font-size: 0.875rem;
		font-family: $font-px-grotesk;

		display: flex;
		text-align: left;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		position: relative;
		width: auto;

		&:focus-visible {
			box-shadow: var(--neo-color-global-border-static-focus) 0 0 0 3px inset;
		}
	}

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.headline {
			font-weight: 700;
			margin: 0;
		}

		.closeButton {
			padding: 0.25rem;

			&:hover {
				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-hover);
			}
			&:active {
				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-active);
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		align-items: center;

		.skipButton {
			padding: 0.5rem 1rem;

			&:hover {
				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-hover);
			}
			&:active {
				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-active);
			}
		}

		.actions {
			margin-left: auto;
			display: flex;
			gap: 0.5rem;

			.backButton {
				padding: 0.5rem 1rem;

				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-default);

				&:hover {
					color: var(--neo-color-global-content-primary-intense);
					background-color: var(--neo-color-global-background-primary-soft-hover);
				}

				&:active {
					color: var(--neo-color-global-content-primary-intense);
					background-color: var(--neo-color-global-background-primary-soft-active);
				}
			}

			.primaryButton {
				padding: 0.5rem 1rem;
				color: var(--neo-color-global-content-primary-on-intense);
				background-color: var(--neo-color-global-background-primary-intense-default);

				&:hover {
					background-color: var(--neo-color-global-background-primary-intense-hover);
				}

				&:active {
					background-color: var(--neo-color-global-background-primary-intense-active);
				}
			}
		}
	}
}
