@import 'theme';

.filterActionBar {
	display: flex;
	overflow: hidden;
	flex-shrink: 0;
	padding: 0.25rem 0.5rem;
	background-color: var(--neo-color-web-app-surface-app);
}

.actionsEnter {
	transform: translateX(43.75rem);
}

.actionsEnterActive {
	transition: transform 250ms;
	transform: translateX(0);
}

.actionsExit {
	transform: translateX(0);
}

.actionsExitActive {
	transition: transform 250ms;
	transform: translateX(43.75rem);
}

.filtersEnter {
	position: absolute;
	padding-top: 0.25rem;
	transform: translateX(-93.75rem);

	@media (pointer: coarse) {
		padding-top: 0;
	}
}

.filtersEnterActive {
	transition: transform 250ms;
	transform: translateX(0);
}

.filtersExit {
	position: absolute;
	padding-top: 0.25rem;
	transform: translateX(0);

	@media (pointer: coarse) {
		padding-top: 0;
	}
}

.filtersExitActive {
	transition: transform 250ms;
	transform: translateX(-93.75rem);
}
