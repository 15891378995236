@import 'theme';

.userLabel {
	background-color: var(--neo-color-web-app-component-card-background-default);
	border-radius: 5rem;
	border: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
	color: var(--neo-color-global-content-neutral-intense);
	cursor: default;
	display: inline-block;
	font-size: 0.75rem;
	font-weight: bold;
	height: 1rem;
	line-height: 0.75rem;
	margin: 0.125rem 0.25rem 0.125rem 0;
	overflow: hidden;
	padding: 0.0625rem 0.4375rem; /* Because of 1px border */
	text-overflow: ellipsis;
	white-space: nowrap;
	&:last-child {
		margin-right: 0;
	}
}
