@import 'theme';

.bold {
	font-weight: 700;
}

.actionButtons {
	display: flex;
	flex-wrap: wrap;

	button {
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		white-space: nowrap;
	}
}
