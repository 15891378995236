@import 'theme';

:global(.react-calendar) {
	width: 19rem;
	margin: auto;
	border-radius: 0.5rem;
	line-height: 1.125em;
	border: none;
	color: var(--neo-color-global-content-neutral-intense);
	font-family:
		PX-Grotesk,
		'system-ui',
		-apple-system,
		'Trebuchet MS',
		Helvetica,
		HelveticaNeue,
		'Helvetica Neue',
		'Segoe UI',
		Roboto,
		Arial,
		sans-serif;
}

:global(.react-calendar__tile) {
	height: 2.5rem !important;
	max-width: 2.5rem;
	background: none;
	text-align: center;
	line-height: 1rem;
	flex: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-block-end: 0;
	padding-block-start: 0;
	padding-inline-end: 0;
	padding-inline-start: 0;
}

:global(.react-calendar__tile) > abbr {
	height: 2.5rem !important;
	width: 2.5rem !important;
	background: none;
	text-align: center;
	display: block;
	border-radius: 50%;
	line-height: 2.5rem;
	border: 1px solid var(--neo-color-global-border-neutral-moderate-default);

	&:hover {
		color: var(--neo-color-global-content-primary-on-intense) !important;
		background-color: var(--neo-color-global-background-primary-intense-default) !important;
		border-color: var(--neo-color-global-border-static-transparent);
	}
}

:global(.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after) {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:global(.react-calendar button) {
	border: 0;
	outline: none;
}

:global(.react-calendar button:enabled:hover) {
	cursor: pointer;
}

:global(.react-calendar__month-view__weekdays) {
	text-align: center;
	font-weight: bold;
	font-size: 0.75rem;
	column-gap: 0.25rem;
}

:global(.react-calendar__month-view__weekdays__weekday) {
	max-width: 2.5rem;
	color: var(--neo-color-global-content-neutral-moderate);
}

:global(.react-calendar__month-view__weekNumbers .react-calendar__tile) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	font-weight: bold;
}

:global(.react-calendar__month-view__days) {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
}

:global(.react-calendar__month-view__days__day--neighboringMonth) {
	color: var(--neo-color-global-content-neutral-moderate);
}

:global(.react-daterange-picker) {
	width: 100%;
	display: inline-flex;
	position: relative;
}

:global(
		.react-daterange-picker,
		.react-daterange-picker *,
		.react-daterange-picker *:before,
		.react-daterange-picker *:after
	) {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:global(.react-daterange-picker--disabled) {
	background-color: var(--neo-color-global-background-neutral-moderate-disabled);
	color: var(--neo-color-global-content-neutral-disabled);
}

:global(.react-daterange-picker__inputGroup) {
	height: 100%;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
}

:global(.react-daterange-picker__inputGroup__input) {
	min-width: 0.54em;
	height: 100%;
	position: relative;
	padding: 0 1px;
	border: 0;
	background: none;
	color: currentColor;
	font: inherit;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

:global(.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button),
:global(.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button) {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

:global(.react-daterange-picker__wrapper) {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem;
	line-height: 1.5rem;

	background-color: var(--neo-color-global-component-input-background-default);
	border: 0 solid var(--neo-color-global-border-static-transparent);
	border-radius: 2px;

	box-shadow: var(--neo-color-global-border-neutral-moderate-default) 0 0 0 1px inset;
	caret-color: var(--neo-color-global-content-primary-intense);
	color: var(--neo-color-global-content-neutral-intense);
	cursor: text;
	font-family:
		PX-Grotesk,
		'system-ui',
		-apple-system,
		'Trebuchet MS',
		Helvetica,
		HelveticaNeue,
		'Helvetica Neue',
		'Segoe UI',
		Roboto,
		Arial,
		sans-serif;
	font-size: 1rem;
	font-stretch: 100%;
	font-weight: 400;
}

.calendar {
	font-family: $font-px-grotesk;
	font-weight: 400;
	border: none;
	margin-left: auto;
	margin-right: auto;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	display: flex !important;

	button {
		@include focussable;
	}
}

:global(.react-calendar__navigation) {
	display: flex;
	margin-bottom: 1em;
	height: 2rem !important;
}

:global(.react-calendar__navigation button) {
	min-width: 2.75rem;
	background: none;
}

:global(.react-calendar__navigation button:disabled) {
	background-color: var(--neo-color-global-background-neutral-soft-disabled);
}

:global(.react-calendar__navigation button:enabled:hover),
:global(.react-calendar__navigation button:enabled:focus) {
	background-color: var(--neo-color-global-background-neutral-soft-hover);
}

:global(.react-calendar__navigation__label) {
	font-weight: 700;
	font-size: 1rem;
	font-family:
		PX-Grotesk,
		'system-ui',
		-apple-system,
		'Trebuchet MS',
		Helvetica,
		HelveticaNeue,
		'Helvetica Neue',
		'Segoe UI',
		Roboto,
		Arial,
		sans-serif;
}

:global(.react-calendar__navigation__label):disabled {
	background-color: var(--neo-color-global-background-static-transparent) !important;
}

:global(.react-calendar__navigation__arrow) {
	width: 2rem !important;
	min-width: 0 !important;
	border-radius: 0.25rem;
	font-size: 1.5rem !important;
	font-weight: bold !important;
	padding-bottom: 3px !important;

	&:disabled {
		opacity: 0 !important;
	}
}

:global(.react-calendar__tile--now) abbr {
	background-color: var(--neo-color-global-background-static-transparent) !important;
	font-weight: 700 !important;
}

:global(.react-calendar__tile--hover) abbr {
	color: var(--neo-color-global-content-primary-intense) !important;
	background-color: var(--neo-color-global-background-primary-moderate-hover) !important;
	border-color: var(--neo-color-global-border-static-transparent);
}

:global(.react-calendar__month-view__weekdays__weekday) abbr {
	text-decoration: none !important;
	border-bottom: none !important;
}

:global(.react-calendar__month-view__days__day):disabled > abbr {
	background: var(--neo-color-global-background-static-transparent) !important;
	color: var(--neo-color-global-content-neutral-disabled) !important;
	border-color: var(--neo-color-global-border-static-transparent);
	cursor: not-allowed !important;
}

:global(.react-calendar__tile--active) abbr {
	color: var(--neo-color-global-content-neutral-intense) !important;
	background-color: var(--neo-color-global-background-primary-moderate-default) !important;
	border-color: var(--neo-color-global-border-static-transparent);

	&:hover {
		background: var(--neo-color-global-background-primary-moderate-hover) !important;
		border-color: var(--neo-color-global-border-static-transparent);
	}
}

:global(.react-calendar__tile--hasActive) abbr {
	background: var(--neo-color-global-background-primary-intense-default) !important;
	color: var(--neo-color-global-content-primary-on-intense) !important;
	border-color: var(--neo-color-global-border-static-transparent);

	&:hover {
		background: var(--neo-color-global-background-primary-intense-hover) !important;
		border-color: var(--neo-color-global-border-static-transparent);
	}
}

:global(.react-calendar__tile--rangeStart) abbr,
:global(.react-calendar__tile--rangeEnd) abbr {
	background: var(--neo-color-global-background-primary-intense-default) !important;
	color: var(--neo-color-global-content-primary-on-intense) !important;
	border-color: var(--neo-color-global-border-static-transparent);

	&:hover {
		background: var(--neo-color-global-background-primary-intense-hover) !important;
		border-color: var(--neo-color-global-border-static-transparent);
	}
}

:global(.react-daterange-picker__range-divider) {
	padding: 0 0.5rem;
	white-space: pre;
}

:global(.react-date-picker__inputGroup__divider),
:global(.react-daterange-picker__inputGroup__leadingZero) {
	display: inline-block;
}

:global(.react-daterange-picker input[type='number']) {
	-moz-appearance: textfield;
	height: 1.5rem;
}

:global(.react-daterange-picker__inputGroup__input--hasLeadingZero) {
	margin-left: -0.6rem;
	padding-left: calc(1px + 0.54rem);
}

:global(.react-daterange-picker__button) {
	border: 0;
	background: var(--neo-color-global-background-static-transparent);
	margin: 0 0 0 auto;
	@include focussable;
	span {
		display: block;
		width: 1rem;
		height: 1.5rem;
		background-color: var(--neo-color-global-content-neutral-on-intense);
		mask-image: url('../media/icons/icon-calendar-dark-line-default-16.svg');
		mask-repeat: no-repeat;
		mask-position: center;
		transition: background-color 0.4s ease;
	}
	&:hover span {
		background-color: var(--neo-color-global-background-primary-intense-hover);
	}
}

:global(.react-daterange-picker__button:enabled) {
	cursor: pointer;
}

:global(.react-daterange-picker__calendar--closed) {
	display: none;
}

:global(.react-daterange-picker__calendar .react-calendar) {
	border-width: thin;
}
