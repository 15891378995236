@import 'theme';

.overlay {
	background: var(--neo-color-global-surface-overlay);
	z-index: $layer-dialog;
	position: fixed;
	backdrop-filter: blur(2px);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	overflow: auto;
}
