@import 'theme';

.snackbar {
	--transition-duration: 400ms;

	position: fixed;
	left: 50%;
	display: flex;
	visibility: hidden;
	transform: translate3d(-50%, -48px, 0);
	transition:
		transform var(--transition-duration) cubic-bezier(0.23, 1, 0.32, 1) 0ms,
		visibility var(--transition-duration) cubic-bezier(0.23, 1, 0.32, 1) 0ms;

	top: auto;
	bottom: 0;

	margin-right: 0;
	margin-left: 0;
	max-width: 100%;

	z-index: $layer-snackbar;

	@include showFullView {
		top: 0;
		bottom: auto;

		margin-right: auto;
		margin-left: auto;
		max-width: inherit;
	}

	&.open {
		visibility: visible;
		transform: translate3d(-50%, 0, 0);
	}
}

.snackbarBody {
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	line-height: 48px;
	max-width: 568px;
	min-width: 288px;
	flex-grow: 0;

	height: 100%;
	overflow: hidden;
	padding: 0 20px;
	border-radius: 0;
	width: 100vw;

	@include showFullView {
		max-width: inherit;
		min-width: inherit;
		flex-grow: 1;
		width: auto;
	}

	&.warning {
		background-color: var(--neo-color-global-background-warning-intense-default);
		color: var(--neo-color-global-content-warning-on-intense);

		a {
			color: var(--neo-color-global-content-warning-on-intense);
			font-weight: 700;

			&:hover {
				color: var(--neo-color-global-content-warning-on-intense);
			}
		}
	}

	&.error {
		background-color: var(--neo-color-global-background-critical-intense-default);
		color: var(--neo-color-global-content-critical-on-intense);

		a {
			color: var(--neo-color-global-content-critical-on-intense);
			font-weight: 700;

			&:hover {
				color: var(--neo-color-global-content-critical-on-intense);
			}
		}
	}

	&.success {
		background-color: var(--neo-color-global-background-success-intense-default);
		color: var(--neo-color-global-content-success-on-intense);

		a {
			color: var(--neo-color-global-content-success-on-intense);
			font-weight: 700;

			&:hover {
				color: var(--neo-color-global-content-success-on-intense);
			}
		}
	}

	&.info {
		background-color: var(--neo-color-global-background-info-intense-default);
		color: var(--neo-color-global-content-info-on-intense);

		a {
			color: var(--neo-color-global-content-info-on-intense);
			font-weight: 700;

			&:hover {
				color: var(--neo-color-global-content-info-on-intense);
			}
		}
	}
}

.snackbarContent {
	--transition-duration: 400ms;
	--transition-delay: 100ms;

	opacity: 0;
	transition: opacity calc(var(--transition-duration) + var(--transition-delay))
		cubic-bezier(0.23, 1, 0.32, 1) 0ms;

	&.open {
		opacity: 1;
		transition: opacity var(--transition-duration) cubic-bezier(0.23, 1, 0.32, 1)
			var(--transition-delay);
	}
}

.messageContainer {
	padding-top: 13px;
	padding-right: 0;
	padding-bottom: 13px;
	padding-left: 0;
	display: inline-block;
	justify-content: space-between;
	align-items: center;
	line-height: 1.5em;
	text-align: center;
	width: 100%;
}
