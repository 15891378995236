.success {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 880px;
	margin: 0 auto;

	.content {
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		line-height: 1.625rem;
		padding: 5rem 1rem 0;
		.message {
			width: 100%;
			word-break: break-word;
			h1 {
				font-size: 1.6125rem;
				font-weight: bold;
				line-height: 2.25rem;
				margin-bottom: 1.5rem;
				margin-top: 0;
			}

			h2 {
				font-size: 1.5rem;

				font-weight: bold;
				line-height: 2rem;
				margin-bottom: 1rem;
				margin-top: 0;
			}

			h3 {
				font-size: 1.125rem;
				font-weight: bold;
				line-height: 1.625rem;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h4 {
				font-size: 1rem;
				font-weight: bold;
				line-height: 1.5rem;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h5 {
				font-size: 0.875rem;
				font-weight: bold;
				line-height: 1.25rem;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			h6 {
				font-size: 0.75rem;
				font-weight: bold;
				line-height: 1.125rem;
				margin-bottom: 0.5rem;
				margin-top: 0;
			}

			p {
				font-size: 1rem;
				line-height: 1.625rem;
			}

			> span {
				color: var(--neo-color-global-content-neutral-intense) !important;
				font-size: 1rem;
			}

			dt {
				font-weight: bold;
				line-height: 1.5rem;
				font-size: 1rem;
				&:not(:first-child) {
					margin-top: 0.5rem;
				}
			}

			dd {
				font-size: 1rem;
				line-height: 1.5rem;
				font-weight: normal;
			}
		}
	}
}

:root {
	--gutter: 5rem;
	--total-columns: 12;
	--aside-columns: 5;
	--content-columns: 4;
	--asideWidth: calc(
		var(--grid-column-width) * var(--aside-columns) + var(--grid-gap) * (var(--aside-columns) - 1) +
			var(--gutter)
	);
	--contentWidth: calc(
		var(--grid-column-width) * var(--content-columns) + var(--grid-gap) *
			(var(--content-columns) - 1)
	);
	--content-margin-left: calc(var(--asideWidth) + var(--grid-column-width) + var(--grid-gap));
	--wrapper-padding: var(--gutter);
	--aside-width: calc(
		calc(var(--column-width) * var(--aside-columns)) +
			(var(--grid-gap) * calc(var(--aside-columns) - 1))
	);
	--content-width: calc(
		(var(--column-width) * var(--content-columns)) +
			(var(--grid-gap) * calc(var(--content-columns)))
	);
	--margin-left: var(--aside-width);
}

@media (max-width: 96rem) {
	:root {
		--aside-columns: 4;
		--content-columns: 5;
	}
}

@media (max-width: 80rem) {
	:root {
		--aside-columns: 3;
		--content-columns: 4;
		--content-width: calc(
			(var(--column-width) * var(--content-columns)) +
				(var(--grid-gap) * calc(var(--content-columns)))
		);
	}
}

@media (max-width: 64rem) {
	:root {
		--aside-columns: 2;
		--content-columns: 2;
		--margin-left: calc(var(--aside-width) + var(--grid-gap));
		--wrapper-padding: 0;
		--asideWidth: calc(
			var(--grid-column-width) * var(--content-columns) + var(--grid-gap) *
				(var(--content-columns) - 1) + var(--gutter)
		);
	}
}

.wrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	background: transparent;
	overflow-y: scroll;
	.content {
		@media (min-width: 56rem) {
			display: flex;
			justify-content: center;
		}
		flex: 1;
		background: var(--neo-color-global-background-neutral-soft-default);

		padding: 5rem var(--gutter) 1.625rem var(--grid-column-width);
		margin-top: 60px;
	}

	.sipgateLogo {
		display: block;
		position: absolute;
		height: 2.5rem;
		z-index: 1001;
		top: 0;
		left: 0;
		margin: 1rem;
	}
}

@media (max-width: 80rem) {
	.wrapper {
		.content {
			padding-left: 7.375rem;
		}
	}
}

@media (max-width: 64rem) and (orientation: portrait) {
	.wrapper {
		height: auto;
		flex-direction: column-reverse;

		.content {
			padding-top: 7rem;
			padding-right: var(--gutter);
			padding-left: var(--gutter);

			width: 100%;
			margin-left: 0;
			margin-bottom: 0;
		}
	}
}

/** iPhone SE landscape **/
@media (max-width: 56rem) and (orientation: landscape) {
	.wrapper {
		height: auto;
		flex-direction: column-reverse;

		& > .sipgateLogo {
			display: block;
			left: unset;
			padding-left: var(--gutter);
		}

		.content {
			padding-top: calc((var(--grid-gap) * 2) + 5rem);
			padding-right: var(--gutter);
			padding-left: var(--gutter);

			width: 100%;
			margin-left: 0;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 48rem) {
	.wrapper {
		.content {
			padding: 0;
			display: flex;
			flex-direction: column;
		}
	}
}
