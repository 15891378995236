@import 'theme';

.mainContainer {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	padding: 1rem;
	color: var(--neo-color-global-content-neutral-intense);
	margin: 0 auto;
	font-family: $font-px-grotesk;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media (max-width: $breakpoint-width-md) {
		flex-direction: column-reverse;
	}

	.icon {
		display: block;
		margin: 1rem;
		max-width: 18.75rem;
	}

	.leftSide {
		max-width: 32.5rem;
		text-align: left;

		.loginHeadline {
			font-size: 2rem;
			margin: 0;
			line-height: 2.375rem;
		}
		.loginDescription {
			font-size: 1.125rem;
			font-family: $font-px-grotesk;
			line-height: 1.5rem;
			margin: 1.875rem 0;
		}

		.buttonContainer {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			& > :first-child {
				margin-right: 1rem;
			}

			.clipboardInput {
				border: 0.125rem solid var(--neo-color-global-border-neutral-moderate-default);
				display: flex;
				padding: 0.5rem 1rem;
				height: 3rem;
				margin: 0.5rem 0;
				border-radius: 0.125rem;

				.inputField {
					width: 100%;
					max-width: 11.25rem;
				}
			}
		}
	}
}
