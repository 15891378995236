.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	white-space: nowrap;
	user-select: none;
}

@mixin focussable() {
	&:focus-visible {
		box-shadow: var(--neo-color-global-border-static-focus) 0px 0px 0px 3px inset;
	}
}
