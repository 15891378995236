@import 'theme';

.timeToLive {
	font-size: 0.875rem;
	padding: 16px 10px;
	text-align: center;

	.icon {
		width: 12px;
		display: none;
		vertical-align: bottom;
		margin-bottom: 1px;
	}

	@media (min-width: $breakpoint-width-md) {
		font-size: 1rem;

		.icon {
			display: initial;
		}

		.tooltipMobile {
			display: none;
		}
	}
}
