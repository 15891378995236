@keyframes grow {
	0% {
		stroke-dasharray: 2.8225 138.3041;
	}

	50% {
		stroke-dasharray: 21.1689 119.9576;
	}

	100% {
		stroke-dasharray: 2.8225 138.3041;
	}
}

@keyframes move {
	0% {
		stroke-dashoffset: 1.4112;
	}

	100% {
		stroke-dashoffset: -139.7153;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		opacity: 0;

		width: 50px;
		stroke: var(--neo-color-global-border-neutral-moderate-default);
		animation: 250ms ease-in-out 250ms forwards fade;

		.spinner {
			animation:
				grow 1.2s cubic-bezier(0.355, 0.005, 0.315, 0.8) infinite,
				move 1.2s cubic-bezier(0.355, 0.005, 0.315, 1) infinite;
		}
	}
}
