@import 'theme';

.help {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		color: var(--neo-color-global-content-neutral-moderate);
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;

		border-radius: 0.25rem;
		border: none;
		outline: inherit;
		text-align: left;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		&:hover {
			background-color: var(--neo-color-global-background-neutral-soft-hover);
		}

		&:active {
			background-color: var(--neo-color-global-background-neutral-soft-active);
		}

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
		}
	}
}
