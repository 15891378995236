@import 'theme';

.container {
	display: flex;
	background-color: var(--neo-color-web-app-surface-app);
	min-height: 100%;
	color: var(--neo-color-global-content-neutral-intense);

	.left {
		display: flex;
		background-color: var(--neo-color-global-surface-section);
		align-items: center;
		flex: 3;
		padding-inline-start: 3rem;
		padding-inline-end: 9.25rem;
		padding-block-start: 8rem;
		padding-block-end: 8rem;
		max-width: 550px;

		.logo {
			position: absolute;
			width: 205px;
			top: 3.5rem;
		}

		.wrapper {
			margin-block-start: auto;
			margin-block-end: auto;

			.greeting {
				margin-block-start: 0;
				font-family: $font-din-next;
				font-size: 2.75rem;
				font-weight: 300;
				line-height: 3rem;

				span {
					&:after {
						content: '👋';
						margin-inline-start: 0.5rem;
					}
				}

				& strong {
					font-family: $font-px-grotesk;
					font-weight: 700;
				}
			}

			.steps {
				list-style: none;
				padding: 0;

				.step {
					position: relative;
					padding-inline-start: 2rem;
					padding-block-end: 2rem;

					&:before {
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 1.5rem;
						height: 1.5rem;
						left: 0;
						border: 2px solid var(--neo-color-global-border-primary-intense-default);
						border-radius: 1rem;
						color: var(--neo-color-global-content-primary-intense);
						font-weight: 700;
						font-size: 0.75rem;
					}

					@for $i from 0 through 2 {
						&.step-#{$i} {
							&:before {
								content: '#{$i + 1}';
								transition-delay: #{0.15s * ($i + 1)};
							}
						}
					}

					&.step-0 {
						&:before {
							background-color: var(--neo-color-global-background-primary-intense-default);
							color: var(--neo-color-global-content-primary-on-intense);
						}
					}

					@keyframes scaleY {
						to {
							transform: scaleY(1);
						}
					}

					&:last-child {
						&:after {
							content: none;
						}
					}

					.title {
						font-weight: 700;
						margin-block-end: 0.5rem;
						line-height: 1.5rem;
					}

					.description {
						font-size: 0.75rem;
						margin-block-end: 0.75rem;
						line-height: 1rem;
					}

					.oshint {
						font-size: 0.563rem;
						margin-block-start: 0.5rem;
						color: var(--neo-color-global-content-neutral-moderate);
					}

					.cta {
						@media (pointer: coarse) {
							display: none;
						}
					}
				}

				&.started {
					.step-0 {
						&:after {
							content: '';
							position: absolute;
							height: calc(100% - 1.5rem);
							width: 2px;
							background-color: var(--neo-color-global-background-primary-intense-default);
							top: 1.5rem;
							left: 0.7rem;
							transform: scaleY(0);
							transform-origin: center top;
							animation: 0.3s ease-out forwards scaleY;

							@media (prefers-reduced-motion) {
								animation: none;
								transform: scaleY(1);
							}
						}
					}

					.step-1 {
						&:before {
							transition-property: background-color, color;
							transition-duration: 0.3s;
							transition-timing-function: linear;
							background-color: var(--neo-color-global-background-primary-intense-default);
							color: var(--neo-color-global-content-primary-on-intense);

							@media (prefers-reduced-motion) {
								transition: none;
							}
						}
					}
				}
			}
		}

		@media (max-width: $breakpoint-width-lg) {
			width: 100%;
			max-width: 100%;
			padding-inline-start: 3rem;
			padding-inline-end: 3rem;
		}
	}

	.right {
		flex: 7;
		position: relative;
		background: linear-gradient(
			180deg,
			var(--neo-color-global-surface-section) 0%,
			var(--neo-color-web-app-surface-app) 100%
		);
		background-size: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2rem;

		img {
			max-width: 100%;
			position: absolute;
		}

		@media (max-width: $breakpoint-width-lg) {
			display: none;
		}

		:nth-child(2) {
			opacity: 0;
		}

		&.started {
			:nth-child(1) {
				opacity: 0;
				transition: opacity 0.3s linear 0.6s;
			}

			:nth-child(2) {
				opacity: 1;
				transition: opacity 0.3s linear 0.6s;
			}
		}
	}
}
