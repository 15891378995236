@import 'theme';

.pureCommunicationButtonsContainer {
	display: flex;
	margin: 1rem 0 0 1rem;

	button {
		@include focussable;

		flex-grow: 1;
		flex-basis: 33%;
		background: var(--neo-color-global-background-primary-intense-default);
		border: none;
		font-size: 0.75rem;
		font-weight: 700;
		color: var(--neo-color-global-content-primary-on-intense);
		padding: 0.625rem;
		line-height: 1;
		margin: 0 0.0625rem;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		&:hover {
			background-color: var(--neo-color-global-background-primary-intense-hover);
		}

		&:active {
			background-color: var(--neo-color-global-background-primary-intense-active);
		}

		&:only-of-type {
			border-radius: 0.5rem;
		}

		&:first-child:nth-last-child(2) {
			border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
		}

		&:last-child:nth-child(2) {
			border-radius: 0.25rem 0.5rem 0.5rem 0.25rem;
		}

		&:first-child:nth-last-child(3) {
			border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
		}

		&:nth-child(2):nth-last-child(2) {
			border-radius: 0.25rem;
		}

		&:last-child:nth-child(3) {
			border-radius: 0.25rem 0.5rem 0.5rem 0.25rem;
		}
	}
}
