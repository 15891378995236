@mixin icon($path) {
	display: inline-block;
	mask: url($path) no-repeat center center;
	mask-size: contain;
	background-color: currentcolor;
	backface-visibility: hidden; /* Prevent background leakage on transition in Firefox */
}

// Our icons have a set amount of free space around the actual icon. This results in them
// not sitting correctly on a texts baseline.
//
// This mixin corrects for this free space based on the icons size.
@mixin icon-inline($size) {
	height: map-get(
		(
			12: 0.75rem,
			16: 1rem,
			24: 1.5rem,
			32: 2rem,
		),
		$size
	);

	margin-bottom: map-get(
		(
			12: -1px,
			16: -1px,
			24: -2px,
			32: -2px,
		),
		$size
	);
}
