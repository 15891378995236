@import 'theme';

.filterlist {
	list-style: none;
	padding: 0;
	margin: 12px 16px;
}

.twoColumns .filterlist {
	columns: 2;
	margin: 0;
}
