@keyframes toggleCheckedPending {
	0%,
	100% {
		transform: translateX(-35%);
	}

	50% {
		transform: translateX(-15%);
	}
}

@keyframes toggleUncheckedPending {
	0%,
	100% {
		transform: translateX(-15%);
	}

	50% {
		transform: translateX(-35%);
	}
}

.ring30Margin {
	margin: 0.1875rem;
}

.translateUnchecked {
	transform: translateX(-50%);
}

:global(.group):active .translateActiveUnchecked {
	transform: translateX(-15%);
}

:global(.group):active .translateActiveChecked {
	transform: translateX(-35%);
}

.animateCheckedPending {
	animation: toggleCheckedPending 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

.animateUncheckedPending {
	animation: toggleUncheckedPending 2s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}
