@import 'theme';

div.wrapper {
	display: flex;
	width: 100%;
	max-height: 100%;
	height: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background: var(--neo-color-web-app-surface-app);
	position: relative;

	main {
		padding: 0 5rem 0 5rem;
		flex: 1 1 auto;
		width: 100%;
		box-sizing: border-box;
		max-height: calc(100% - 5rem);
		min-height: calc(100% - 5rem);
		scroll-snap-type: y mandatory;
		scroll-padding-block-end: 5rem;
		scroll-padding-block-start: 1rem;
		overflow-y: auto;
		position: absolute;

		h1 {
			font-size: 2.75rem;
			font-weight: 700;
			line-height: 3rem;
			padding-top: 3rem;
			font-family: $font-px-grotesk;
			color: var(--neo-color-global-content-neutral-intense);
			text-align: left;
			scroll-snap-align: start;

			margin: 0 auto 1rem auto;
		}

		p.subline {
			text-align: left;
		}

		> p {
			font-size: 1.125rem;
			font-weight: 400;
			line-height: 1.5rem;
			font-family: $font-px-grotesk;
			color: var(--neo-color-global-content-neutral-moderate);
			text-align: center;
			margin: 0 auto 1rem auto;
		}

		div.noResults {
			margin-top: 3rem;
		}

		div.tableContainer {
			div.search {
				margin-top: 3rem;
				max-width: 35rem;
			}

			table {
				margin-top: 1rem;
				overflow-x: auto;
				width: 100%;
				border-collapse: separate;
				border-spacing: 0 1rem;
				table-layout: fixed;

				thead {
					height: 1rem;

					th {
						text-align: left;
						font-family: $font-din-next;
						color: var(--neo-color-global-content-neutral-moderate);
						font-size: 1rem;
						font-weight: 300;
						line-height: 0;
						white-space: nowrap;
						padding-left: 1rem;
						padding-right: 1rem;

						> span {
							display: inline-flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							gap: 1rem;
						}
					}
				}

				tbody {
					tr {
						border-radius: 0.5rem;
						scroll-snap-align: start end;
						scroll-margin-bottom: 5rem;
						height: 5.125rem;

						td {
							border: 0.125rem solid var(--neo-color-global-border-neutral-soft-default);
						}

						&:not(.hasErrors) {
							background: var(--neo-color-web-app-component-card-background-default);
						}

						&:focus-within:not(.hasErrors) {
							background: var(--neo-color-web-app-component-card-background-hover);

							td {
								border-color: var(--neo-color-global-border-primary-intense-default) !important;
							}
						}

						&.hasErrors {
							background-color: var(--neo-color-global-background-critical-soft-default);

							td {
								border-color: var(--neo-color-global-border-critical-intense-default);
								padding-bottom: 4px !important;
							}
						}

						@keyframes fadeOut {
							0% {
								opacity: 1;
								transform: translateX(0);
							}

							99% {
								opacity: 0.01;
							}

							100% {
								opacity: 0;
								transform: translateX(-5rem);
							}
						}

						&.deleting {
							@media (prefers-reduced-motion: no-preference) {
								background-color: var(--neo-color-global-background-critical-soft-default);
								animation: fadeOut 0.25s;
								animation-fill-mode: forwards;

								td {
									border-color: var(--neo-color-global-border-critical-moderate-default);
								}
							}
						}

						td {
							padding: 0.5rem 0 0.5rem 0;
							vertical-align: middle;

							> * {
								display: block;
							}

							&:first-child {
								border-top-left-radius: 0.5rem;
								border-bottom-left-radius: 0.5rem;
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
								border-right: 0;
								padding: 1rem 1rem 1.375rem 1.5rem;
							}

							&:not(:last-child):not(:first-child) {
								border-radius: 0;
								border-left: 0;
								border-right: 0;
								padding: 1rem 1rem 1.375rem 1rem;
							}

							&:last-child {
								border-top-left-radius: 0;
								border-bottom-left-radius: 0;
								border-top-right-radius: 0.5rem;
								border-bottom-right-radius: 0.5rem;
								border-left: 0;
								padding: 0 1.5rem 0 1.5rem;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
	}

	footer {
		position: absolute;
		bottom: 0;
		left: 0;
		align-self: flex-end;
		box-shadow: 0rem -0.25rem 0.5rem 0rem var(--neo-color-global-shadow-default);
		background: var(--neo-color-global-surface-menu);
		margin-top: auto;
		flex: 0 0 auto;
		padding: 1rem 5rem;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.preview {
			flex: 0 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}

		.buttons {
			display: flex;
			gap: 2rem;
			flex-direction: row;
		}
	}
}
