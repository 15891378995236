@import 'theme';

.systemLabel {
	background-color: var(--neo-color-global-border-neutral-moderate-default);
	border-radius: 5rem;
	border: 0.0625rem solid transparent; /* To match spacing of UserLabel.scss */
	color: var(--neo-color-global-content-neutral-intense);
	cursor: default;
	display: inline-block;
	font-size: 0.75rem;
	font-weight: bold;
	height: 1rem;
	line-height: 0.75rem;
	overflow: hidden;
	padding: 0.0625rem 0.4375rem; /* Because of 1px border */
	text-overflow: ellipsis;
	white-space: nowrap;
}

.strong {
	background-color: var(--neo-color-global-background-critical-moderate-default);
	color: var(--neo-color-global-content-critical-intense);
}

.weak {
	background-color: var(--neo-color-global-background-warning-moderate-default);
	color: var(--neo-color-global-content-warning-intense);
}

.gentle {
	background-color: var(--neo-color-global-background-success-moderate-default);
	color: var(--neo-color-global-content-success-intense);
}
