@import 'theme';

.actionButtons {
	display: flex;
	flex-wrap: wrap;

	button {
		margin-top: 12px;
		margin-right: 8px;
		white-space: nowrap;
	}
}

.bold {
	font-weight: 700;
}

.player {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	align-items: center;
	max-width: 700px;
}

.player > :first-child {
	flex-grow: 1;
}
