@import 'theme';

.phonenumberFilter {
	.suggestionhead {
		font-size: 0.75rem;
		padding: 0.75rem 1rem;
		font-weight: normal;
	}

	.resetButton {
		@include focussable;

		padding: 1rem;
		width: 100%;

		@media (min-width: $breakpoint-width-lg) {
			padding: 0.5rem;
		}

		@media (min-width: $breakpoint-width-2xl) {
			padding: 0.5rem 1rem;
		}
	}

	ul {
		list-style: none;
		padding: 0;

		.suggestion {
			cursor: pointer;
			overflow: hidden;
			line-height: 1;
			& > span {
				display: inline-block;
				white-space: nowrap;

				// We do not use ellipsis due to weird spacing issues
				text-overflow: '...';
				overflow: hidden;
				vertical-align: bottom;
			}

			& > button {
				// hack that ensures the background can be set in other places
				background-color: var(--neo-color-global-background-static-transparent);
				text-align: left;
			}

			.highlighted {
				color: var(--neo-color-global-content-info-moderate);
			}
		}

		.suggestion.selected {
			background-color: var(--neo-color-global-background-neutral-moderate-hover);
		}

		.suggestion.exact {
			border-top: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);

			@media (min-width: $breakpoint-width-2xl) {
				border-bottom-left-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}

			span:first-child {
				max-width: 30%;
			}
		}

		.suggestion.reset {
			height: auto;
			text-align: center;

			border-top: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);

			@media (min-width: $breakpoint-width-2xl) {
				border-bottom-left-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}

			&:first-child {
				border: none;
			}
		}
	}
}

.phonenumberDropdown {
	width: 320px;
	h5 {
		margin: -0.5rem 0.5rem 0.5rem 1rem;
	}
	ul {
		margin-top: 0;
	}
	form {
		margin: 1rem;
	}
}

.phonenumberSlideIn {
	width: auto;
	padding: 0;
	margin: 1rem 0.75rem 1rem 0;
	h5 {
		margin: 0.5rem 0 0.5rem 1rem;
	}
	ul {
		margin: 0 0 1rem;
	}
}
