/* normalize.css sets high-specificity stuff on search inputs, so we need to override those */
input.resetNormalize[type='search'] {
	box-sizing: border-box;
	margin: 0;
}

input.resetNormalize[type='search']::-webkit-search-decoration,
input.resetNormalize[type='search']::-webkit-search-cancel-button,
input.resetNormalize[type='search']::-webkit-search-results-button,
input.resetNormalize[type='search']::-webkit-search-results-decoration {
	display: none;
}
