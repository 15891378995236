@import 'theme';

.menuButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	outline: none;
	background: none;
	border: none;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 0.25rem;
	border: none;
	padding: 0 0.5rem;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
		opacity, box-shadow, transform, filter, backdrop-filter;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;

	@media (min-width: $breakpoint-width-xl) {
		display: none;
	}

	div {
		background: var(--neo-color-global-content-neutral-intense);
		border-radius: 0.625rem;
		width: 1.5rem;
		height: 0.125rem;
		margin: 0.125rem 0;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;
	}

	&:focus-visible {
		box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
	}

	&:hover {
		background-color: var(--neo-color-global-background-neutral-soft-hover);
		div {
			&:nth-child(1) {
				width: 1.375rem;
			}

			&:nth-child(2) {
				width: 1rem;
			}

			&:nth-child(3) {
				width: 1.25rem;
			}
		}
	}

	&:active {
		background-color: var(--neo-color-global-background-neutral-soft-active);
	}
}
