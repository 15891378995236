.borderSpacing {
	border-spacing: 0 1rem;
}

.maxWidthXs {
	max-width: 5rem;
}

.maxWidthSm {
	max-width: 10rem;
}

.maxWidthMd {
	max-width: 20rem;
}

.maxWidthLg {
	max-width: 25rem;
}
