@import './assets/icons/panda-icons/icons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--overlay-translucent: theme('colors.overlay.translucent');
	}

	/* The following is copied from app.scss to make the components in Storybook look like they would in the AppWeb */
	html {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		box-sizing: border-box;
		font-family: 'PX-Grotesk', 'BlinkMacSystemFont', '-apple-system', 'Trebuchet MS', 'Helvetica',
			'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Arial', 'sans-serif';
	}

	* {
		-webkit-tap-highlight-color: transparent;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		border-radius: 0;
		text-rendering: geometricPrecision;
		box-sizing: inherit;
	}

	*:focus {
		outline: 0;
	}

	figure {
		margin: 0;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border-width: 0;
	}
	/* END */

	/* Remove this snippet when preflight and or normalize.css is introduced into this codebase */
	*,
	::before,
	::after {
		box-sizing: border-box;
		border-width: 0;
		border-style: solid;
		border-color: currentColor;
	}

	button {
		margin: 0;
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: 0;
	}
	/* END */
}

@layer utilities {
	.hyphens {
		/* Breaks words with a hyphen, but only works if html lang="xyz" is set correctly */
		word-break: break-word;
		hyphens: auto;
	}

	.ring-focus-inset {
		@apply ring ring-inset ring-neo-color-global-border-static-focus;
	}

	.ring-focus {
		@apply ring ring-neo-color-global-border-static-focus;
	}
}
