@import 'theme';

.pill {
	display: inline-flex;
	align-items: center;
	height: 1.25rem;
	padding: 0 0.5rem;
	font-family: $font-px-grotesk;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 0.75rem;
	text-transform: uppercase;
	border-radius: 0.5rem;

	&.new {
		color: var(--neo-color-web-app-component-pill-content-default);
		background: var(--neo-color-web-app-component-pill-background-default);
	}

	&.beta {
		color: var(--neo-color-global-content-accent-2-intense);
		background: var(--neo-color-global-background-accent-2-moderate-default);
		padding: 0 0.5rem;
	}

	&.ai {
		color: var(--neo-color-global-content-accent-2-intense);
		background: var(--neo-color-global-background-accent-2-moderate-default);
		overflow: hidden;

		/*
			Safari won't show the shadow on first render.
			So we shift the pseudo element out of the pill and let the shadow fall into into it.
			This renders the ::before element in Firefox and causes spacing issues, which was fixed by applying flexbox to the parent element.
		*/
		&:before {
			content: '✨';
			font-size: 0.75rem;
			text-shadow: 0 1rem 0 var(--neo-color-global-content-accent-2-intense);
			margin: 0 0.125rem 0 0;
			position: relative;
			top: -1rem;
		}
	}
}
