@import 'theme';

.dropdownWrapper {
	position: fixed;
	display: none;
	z-index: $layer-dropdown;

	&.preventBackgroundInteraction {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&.visible {
		display: block;
	}
}

.dropdown {
	position: fixed;
	z-index: $layer-dropdown;
	border-radius: 4px;
	box-shadow:
		var(--neo-color-global-shadow-subtle) 0 1px 6px,
		var(--neo-color-global-shadow-subtle) 0 1px 4px;
	background-color: var(--neo-color-global-surface-menu);
	min-width: 11.5625rem;

	// Center on small screen
	@media (max-width: $breakpoint-width-sm) {
		left: 50% !important;
		transform: translateX(-50%);
		min-width: calc(100vw - 16px);
	}
}
