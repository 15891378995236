$layer-base-ui: 0;
$layer-controlbar: 1;
$layer-text-input-autocomplete: 800;
$layer-appbar: 1100;
$layer-appbar-mobile: 1100;
$layer-loadingbar: 1200;
$layer-close-navigation: 1300;
$layer-intercom: 1400;
$layer-zopim: $layer-intercom;
$layer-userswitch: 1420;
$layer-menu: 1425;
$layer-dropdown: 1450;
$layer-dialog: 1450;
$layer-tooltip: 1600;
$layer-snackbar: 2900;
$layer-drag-and-drop-preview: 3000;
