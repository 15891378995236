@import 'theme';

.container {
	/**
	 * AuthenticatedLayout sets all line-heights to 1 which is not preferred.
	 * Disabling it there would break the app-web so we scope it to panda views.
	 */
	line-height: initial;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	background: var(--neo-color-web-app-surface-app);

	// Padding bottom is to accomodate chatbot bubble
	padding: 2rem 0 6rem 0;

	max-width: calc(64rem + 2rem);
	--routing-grid-max-width: 64;

	@media (min-width: $breakpoint-width-md) {
		max-width: calc(64rem + 5rem);
		padding: 2.5rem 0 6rem 0;
	}

	@media (min-width: $breakpoint-width-lg) {
		padding: 3.5rem 0 6rem 0;
	}

	@media (min-width: $breakpoint-width-xl) {
		max-width: calc(64rem + 10rem);
		padding: 3.5rem 0 6rem 0;
	}

	&.fullWidth {
		max-width: none;
		--routing-grid-max-width: 999;
	}
}
