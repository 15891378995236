@import 'theme';

.reset {
	width: 100%;
	cursor: pointer;
	overflow: hidden;
	line-height: 2;
	text-align: center;
	border: none;
	border-top: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	color: var(--neo-color-global-content-primary-intense);
	background-color: var(--neo-color-global-background-primary-soft-default);
	margin-top: 1rem;

	&:hover {
		background-color: var(--neo-color-global-background-primary-soft-hover);
	}
}

.calendarWrapper {
	padding: 0.5rem 1.25rem 0.25rem;
	&:last-child {
		padding-bottom: 1.25rem;
	}

	@media (max-width: calc($breakpoint-width-2xl - 0.0625rem)) {
		:global(.react-calendar) {
			width: auto;
		}

		:global(.react-calendar__month-view__weekdays__weekday) {
			max-width: 2rem;
		}

		:global(.react-calendar__tile) {
			height: 2rem !important;
			max-width: 2rem;
		}

		:global(.react-calendar__tile) > abbr {
			height: 2rem !important;
			width: 2rem !important;
			line-height: 2rem;
		}
	}

	&.slideIn {
		padding: 0.5rem 1.25rem 0.25rem;

		:global(.react-calendar) {
			width: auto;
		}

		:global(.react-calendar__month-view__weekdays__weekday) {
			max-width: 2rem;
		}

		:global(.react-calendar__tile) {
			height: 2rem !important;
			max-width: 2rem;
		}

		:global(.react-calendar__tile) > abbr {
			height: 2rem !important;
			width: 2rem !important;
			line-height: 2rem;
		}

		@media (max-width: calc($breakpoint-width-md - 0.0625rem)) {
			:global(.react-calendar) {
				width: 19rem;
			}

			:global(.react-calendar__month-view__weekdays__weekday) {
				max-width: 2.5rem;
			}

			:global(.react-calendar__tile) {
				height: 2.5rem !important;
				max-width: 2.5rem;
			}

			:global(.react-calendar__tile) > abbr {
				height: 2.5rem !important;
				width: 2.5rem !important;
				line-height: 2.5rem;
			}
		}
	}
}

.dropdown {
	max-height: none;
}
