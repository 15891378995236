@import 'theme';

/* stylelint-disable-next-line selector-max-type -- This html is a specificity hack */
html .overlay {
	background: var(--neo-color-global-surface-overlay);
	backdrop-filter: blur(0);
}

.panel {
	display: flex;
	flex-direction: column;

	font-family: $font-px-grotesk;
	min-width: 20rem;
	max-width: 100%;
	height: 100%;
	position: fixed;
	background: var(--neo-color-global-surface-menu);
	box-shadow: 0 0 1.5rem var(--neo-color-global-shadow-default);
	top: 0;
	right: 0;

	color: var(--neo-color-global-content-neutral-intense);

	@media (max-width: $breakpoint-width-md) {
		width: 100%;
	}

	.header {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--neo-color-global-background-neutral-soft-default);
		padding: 1rem;

		.headline {
			font-size: 1rem;
			font-weight: 700;
			color: var(--neo-color-global-content-neutral-intense);
			margin: 0;
		}

		.rightClose {
			@media (max-width: $breakpoint-width-md) {
				visibility: hidden;
			}
		}

		.leftClose {
			visibility: hidden;

			@media (max-width: $breakpoint-width-md) {
				visibility: visible;
			}
		}
	}

	.content {
		overflow: auto;
		flex-grow: 1;
		position: relative;
		overflow-x: hidden;
	}
}

.overlay.enter {
	background: transparent;
}

.overlay.enter.enterActive {
	background: var(--neo-color-global-surface-overlay);
	transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 400ms;
}

.overlay.exit {
	background: var(--neo-color-global-surface-overlay);
}

.overlay.exit.exitActive {
	background: transparent;
	transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 400ms;
}

.overlay.enter .panel {
	transform: translateX(100%);
}

.overlay.enter.enterActive .panel {
	transform: translateX(0);
	transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 400ms;
}

.overlay.exit .panel {
	transform: translateX(0);
}

.overlay.exit.exitActive .panel {
	transform: translateX(100%);
	transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 400ms;
}
