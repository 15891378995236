@import 'theme';

li.groupElement {
	&.closed > button > span.text {
		display: none;
	}

	&.transitioning {
		button {
			.icon {
				display: none;
			}
		}
	}

	button {
		display: flex;
		gap: 0.5rem;
		height: 3rem;
		flex-direction: row;
		align-items: center;
		font-family: $font-px-grotesk;
		font-size: 1rem;
		line-height: 1.25rem;
		font-style: normal;
		font-weight: bold;
		border-radius: 0.5rem;
		color: var(--neo-color-global-content-neutral-moderate);
		text-decoration: none;
		padding: 0.5rem 0.75rem;
		background-color: var(--neo-color-global-background-static-transparent);
		border: 0;
		width: 100%;
		position: relative;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-timing-function: ease-in-out;
		transition-duration: 150ms;
		@include focussable;

		.icon {
			margin-left: auto;
			transition-property: color, background-color, border-color, text-decoration-color, fill,
				stroke, opacity, box-shadow, transform, filter, backdrop-filter;
			transition-duration: 150ms;
			transition-timing-function: ease-in-out;
		}

		&.open {
			color: var(--neo-color-global-content-primary-intense);
			.icon {
				transform: scaleY(-1);
			}
		}
	}

	ul.group {
		margin-left: 2.5rem;

		li.groupTitle {
			color: var(--neo-color-global-content-neutral-intense);
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: 0.875rem;
			letter-spacing: 0.0625rem;
			padding: 0.75rem 0.75rem 0.375rem 0.75rem;
			margin: 0;
			text-overflow: ellipsis;
			text-transform: uppercase;
			cursor: default;
		}
	}

	ul:not(.open) {
		display: none;
	}

	ul.open {
		display: flex;
	}

	&.closed {
		button {
			&::after {
				content: unset;
			}
		}
	}

	&.closed:not(.transitioning) {
		position: relative;

		ul {
			position: fixed;
			left: 5.25rem;
			border-radius: 0.25rem;
			box-shadow: 0 4px 24px var(--neo-color-global-shadow-default);
			padding: 0;
			transform: translateY(-42px);
			margin-left: 0;
			z-index: 900;

			li {
				a {
					span {
						display: inline-flex;
					}
				}
			}
		}
	}

	&:not(.closed) {
		ul.group {
			li.groupTitle {
				display: none;
			}
		}
	}

	&.transitioning {
		ul {
			display: none;
		}
	}

	ul {
		background-color: var(--neo-color-global-surface-menu);
		border-radius: 0.5rem;
	}

	&.closed {
		ul {
			li {
				a {
					border-radius: 0;
				}
			}
		}
	}

	button {
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;
		&:hover {
			background-color: var(--neo-color-global-background-primary-soft-hover);
		}

		&.hasOpenSubPage {
			&.open {
				span {
					color: var(--neo-color-global-content-neutral-intense);
				}
			}

			&:not(.open) {
				color: var(--neo-color-global-content-primary-intense);
				background-color: var(--neo-color-global-background-primary-soft-active);
			}
		}
	}
}
