@import 'theme';

.cageDialog {
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0.5rem;

	@include isSmallerThanSmallDevice {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}
