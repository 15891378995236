@import 'theme';

.list {
	overflow: auto;
	flex-grow: 1;
	font-size: 1rem;
	margin: 1rem -1rem 0;
	padding: 0;
	list-style: none;

	// special bottom spacing for intercom
	li:last-child {
		margin-bottom: 7.5rem;
	}

	.entry {
		padding: 0.25rem 1rem;
		border-bottom: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
		display: flex;
		justify-content: space-between;
		align-items: center;

		label {
			max-width: 14.25rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.mobileCloseContainer {
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;

	@media (min-width: $breakpoint-width-md) {
		display: none;
	}
}

.createLabelLinkWrapper {
	display: flex;
	flex-direction: row-reverse;
	margin-top: 1rem;
}
