.horribleCornerHack {
	background: linear-gradient(
		var(--neo-color-web-app-surface-app) 15%,
		var(--neo-color-global-background-static-transparent) 15%,
		var(--neo-color-global-background-static-transparent) 85%,
		var(--neo-color-web-app-surface-app) 85%
	);
}

.horribleCornerHack:after {
	background: linear-gradient(
		var(--neo-color-web-app-component-card-background-default) 15%,
		var(--neo-color-global-background-static-transparent) 15%,
		var(--neo-color-global-background-static-transparent) 85%,
		var(--neo-color-web-app-component-card-background-default) 85%
	);
}
