@import 'theme';

.content {
	top: 0;
	position: absolute;
	background-color: var(--neo-color-global-surface-menu);
	padding: 16px;

	height: 100%;
	width: 320px;

	display: flex;
	flex-direction: column;

	@media (max-width: $breakpoint-width-md) {
		width: 100%;
	}
}

.subPage {
	z-index: $layer-dialog + 10;
}

.subPage.enter {
	transform: translateX(100%);
}

.subPage.enter.enterActive {
	transform: translateX(0);
	transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
}

.subPage.exit {
	transform: translateX(0);
}

.subPage.exit.exitActive {
	transform: translateX(100%);
	transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
}
