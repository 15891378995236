@import 'theme';

.closeIconContainer {
	top: 0;
	height: auto;
	padding: 1rem;
	z-index: calc(#{$layer-dialog} + 1);
	right: 0;
	position: fixed;

	@media (min-width: $breakpoint-width-md) {
		position: absolute;
	}
}

.backIconContainer {
	top: 0;
	height: auto;
	padding: 1rem;
	z-index: calc(#{$layer-dialog} + 1);
	position: absolute;
}

.backIcon {
	transition: opacity 0.2s ease;

	width: 1.25rem;
	height: 1.25rem;

	@media (min-width: $breakpoint-width-md) {
		width: 1.5625rem;
		height: 1.5625rem;
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}
}

.additionalContent {
	position: fixed;
	top: 0;
	right: 0;
}

.content {
	width: 100vw;
	height: 100%;
	overflow: auto;

	@media (min-width: $breakpoint-width-md) {
		background: var(--neo-color-global-surface-dialog);
		width: 28.125rem;
		height: auto;
		position: relative;
		margin: 0 auto;
		border-radius: 0.5rem;
	}
}
