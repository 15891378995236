/*
 * I'd argue important is fine here, because for the duration of the transition
 * we are definitely more important than anything else in regards to opacity.
 */

.enter,
.enter ~ * {
	opacity: 0;
	transition: opacity 75ms ease-out !important;
}

.enter.enterActive,
.enter.enterActive ~ * {
	opacity: 1 !important;
}

.exit,
.exit ~ * {
	opacity: 1;
	transition: opacity 75ms ease-in !important;
}

.exit.exitActive,
.exit.exitActive ~ * {
	opacity: 0 !important;
}
