@import 'theme';

.wrapper {
	--backdropDisplay: block;
	--border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	--headerMargin: 3.5rem 3.75rem 0.5rem 3.75rem;
	--contentMargin: 0 3.75rem 3.5rem;
	--contentWidth: auto;
	--centerWidth: 26.25rem;

	.logo {
		display: var(--logoDisplay, block);
		position: absolute;
		left: 3.5rem;
		top: 3.5rem;
		width: 8rem;
	}

	margin: 0 auto;
	height: 100%;
	color: var(--neo-color-global-content-neutral-intense);

	display: flex;
	align-items: center;
	justify-content: center;

	background-size: cover;

	.backdrop {
		display: var(--backdropDisplay, none);
		position: fixed;
		z-index: -1;
		width: 66rem;
		height: 67.5rem;

		background: url('img/bg-fade.svg') no-repeat center center;
		animation:
			opacity 0.5s linear 0.5s 1 forwards,
			waber 7.5s linear 1s infinite alternate;
		transform-box: fill-box;
		transform-origin: center;
		opacity: 0;

		@media (prefers-reduced-motion) {
			animation: none;
			opacity: 1;
		}

		// Firefox has problems with svg gradients in background-image. we use a png instead
		@-moz-document url-prefix() {
			background: url('img/bg-fade.png') no-repeat center center;
		}

		@keyframes opacity {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes waber {
			0% {
				transform: scale(1) skew(0deg) rotate(0deg);
			}
			100% {
				transform: scale(0.75) skew(10deg, 10deg) rotate(15deg);
			}
		}
	}

	.center {
		display: flex;
		flex-direction: column;
		height: var(--centerHeight);
		width: var(--centerWidth);

		background: var(--neo-color-global-surface-dialog);
		border-radius: 0.5rem;
		border: var(--border, 'none');

		.backButton {
			margin: 1.5rem 0 0 1.5rem;
		}

		.header {
			font-size: 2.25rem;
			text-align: center;
			font-feature-settings: 'calt' off;
			font-family: $font-din-next;

			border-top-right-radius: 0.5rem;
			border-top-left-radius: 0.5rem;

			margin: var(--headerMargin, 3.75rem);

			& strong {
				display: block;
				font-style: normal;
				font-family: $font-px-grotesk;
				font-size: 2rem;
				font-weight: 700;
				line-height: 2.5rem;

				&:after {
					content: '👋';
					margin-inline-start: 0.5rem;
				}
			}
		}

		.content {
			margin: var(--contentMargin);
			width: var(--contentWidth);

			.setPassword {
				.title {
					font-size: 1.125rem;
					line-height: 1.125rem;
					font-weight: 700;
					text-align: center;
				}

				p {
					font-size: 0.875rem;
					text-align: center;
				}

				& > :last-child {
					margin-top: 1rem;
				}
			}
		}

		.hr {
			border-top: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
			margin: 1.75rem 0 1.75rem;
		}

		.ssoHint {
			font-size: 0.875rem;
			text-align: center;
			margin: 0;
		}

		.idp {
			display: block;
			text-decoration: none;
			width: 100%;
			height: 2.5rem;
			padding: 0;
			margin: 0.5rem 0;
			float: left;
			background: var(--neo-color-global-background-static-transparent);

			&:focus,
			&:focus-visible,
			&:focus-within {
				background: var(--neo-color-global-border-static-focus);
				padding: 0.1875rem;
			}

			&:hover > span {
				background: var(--neo-color-global-background-neutral-soft-hover);
			}

			span {
				box-sizing: border-box;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				height: 100%;

				background: var(--neo-color-global-background-neutral-soft-default);
				border-radius: 0.125rem;

				color: var(--neo-color-global-content-neutral-intense);

				font-size: 0.875rem;
				line-height: 0.875rem;
				font-weight: 600;
				font-family:
					PX-Grotesk,
					BlinkMacSystemFont,
					-apple-system,
					Trebuchet MS,
					Helvetica,
					'HelveticaNeue',
					'Helvetica Neue',
					'Segoe UI',
					Roboto,
					Arial,
					sans-serif;

				&.google:before {
					content: url('./img/logo_google.svg');
					display: inline-block;
					width: 1.125rem;
					height: 1.125rem;
				}
				&.microsoft:before {
					content: url('./img/logo_microsoft.svg');
					display: inline-block;
					width: 1.125rem;
					height: 1.125rem;
				}
				&.apple:before {
					content: url('./img/logo_apple.svg');
					display: inline-block;
					width: 1.125rem;
					height: 1.125rem;
				}
			}
		}

		.first {
			margin-top: 1.25rem;
		}

		.hidden {
			display: none;
		}
	}
}

.email {
	font-size: 1.125rem;
	text-align: center;

	.loginHint {
		font-size: 0.875rem;
		margin: 2.25rem 0 1rem;
	}
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: $breakpoint-width-md) {
	.wrapper {
		--logoDisplay: none;
		--contentMargin: 0 auto 3.5rem;
		--contentWidth: 20rem;
		--centerWidth: 100%;
		--centerHeight: 100%;
		--backdropDisplay: none;
	}
}
