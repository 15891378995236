$font-px-grotesk:
	PX-Grotesk,
	BlinkMacSystemFont,
	-apple-system,
	Trebuchet MS,
	Helvetica,
	'HelveticaNeue',
	'Helvetica Neue',
	'Segoe UI',
	Roboto,
	Arial,
	sans-serif;

$font-px-grotesk-mono: 'PX-Grotesk-Mono', 'Monaco', 'Lucida Console', 'monospace';

$font-din-next: 'DIN-Next', 'sans-serif';
