@import 'theme';

.searchInput {
	display: flex;
	align-items: center;
	height: 29px;
	border-bottom: 1px solid var(--neo-color-global-border-neutral-intense-default);

	input {
		appearance: textfield;
		flex-grow: 1;
		padding: 0 8px 0 0;
		font-size: 1rem;
		border: none;
		box-shadow: none;
		line-height: 1.5em;
		min-width: 0;
		width: 100%;
		background-color: transparent;
		text-overflow: ellipsis;

		&::placeholder {
			color: var(--neo-color-global-content-neutral-moderate);
		}
	}

	.icon {
		width: 16px;
		height: 16px;
		margin: 0 8px 0 12px;
		flex-shrink: 0;
	}
}
