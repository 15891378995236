@import 'theme';

.filter {
	font-size: 1rem;
	padding: 0 0.25rem 0.5rem 1rem;
	color: var(--neo-color-global-content-neutral-intense);
	background: var(--neo-color-global-surface-menu);
	margin-bottom: 1rem;
	border-bottom: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
}

.heading {
	font-size: 0.75rem;
	font-weight: 700;
	margin: 1.5rem 1rem 0.25rem 1rem;
	padding: 0;
	text-transform: uppercase;
}
