.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	// Required to integrate smoothly when rendered into a `display: flex` parent
	width: 100%;

	> button {
		margin: 0.5rem auto 0 auto;
	}
}
