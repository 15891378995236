@import 'theme';

.deleteWarning {
	font-size: 1rem;
}

.buttonWrapper {
	display: flex;
	justify-content: space-between;
}
