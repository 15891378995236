@import 'theme';

.currentPage {
	padding: 7px 16px;
	border: 1px solid var(--neo-color-global-border-neutral-intense-default);
	border-left: none;
	white-space: nowrap;

	display: flex;
	align-items: center;
}

.totalEntries {
	margin-left: 4px;

	@media (max-width: $breakpoint-width-lg) {
		display: none;
	}
}
