input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

label > input[type='checkbox'] {
	margin-right: 8px;
}
