@import 'theme';

.container {
	min-height: 0;
	padding-bottom: 2.625rem;
}

.contentContainer {
	text-align: center;
	line-height: 1.5;
}
