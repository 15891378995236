@import 'theme';

.filterlist {
	list-style: none;
	padding: 0;
	margin: 0.75rem 1rem;
}

.twoColumns .filterlist {
	margin: 0;
}

.spinnerWrapper {
	min-height: 4.6875rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
}

.spinner {
	width: 3.125rem;
	height: 3.125rem;
}

.errorState,
.emptyState {
	margin: 1rem;
	max-width: 15.625rem;
}

.headline {
	font-size: 1rem;
	font-weight: 700;
	margin: 0.5rem 0 0 0;
}

.description {
	p {
		margin: 0.25rem 0 0.5rem 0;
		padding: 0;
		font-weight: normal;
	}
}

.twoColumns .errorState,
.twoColumns .emptyState {
	margin: 0;
	max-width: 18rem;
}
