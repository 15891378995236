.border {
	box-shadow: inset 0 -0.0625rem var(--neo-color-global-border-neutral-moderate-default);
}

.borderHover:hover {
	box-shadow: inset 0 -0.0625rem var(--neo-color-global-border-neutral-moderate-default);
}

.borderSelected {
	box-shadow: inset 0 -0.125rem var(--neo-color-global-border-primary-intense-default);
}

.borderDisabledSelected {
	box-shadow: inset 0 -0.125rem var(--neo-color-global-border-primary-intense-disabled);
}
