@import 'theme';

.errorContent {
	padding: 16px;

	width: 320px;

	@media (max-width: $breakpoint-width-md) {
		width: 100%;
	}
}

.errorState {
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;

	.illustrationContainer {
		width: 100%;

		margin-bottom: 24px;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 700;
		margin-bottom: 8px;
	}

	p {
		font-weight: 400;
		font-size: 0.875rem;
		margin: 0;
	}
}
