@import 'theme';

.wrapper {
	--backdropDisplay: block;
	--border: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
	--headerMargin: 3.5rem 3.75rem 0.5rem 3.75rem;
	--contentMargin: 0 3.75rem 3.5rem;
	--contentWidth: auto;
	--centerWidth: 32rem;
	min-height: 100%;
	margin: 0 auto;
	color: var(--neo-color-global-content-neutral-intense);
	background-color: var(--neo-color-web-app-surface-app);
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;

	.logo {
		display: var(--logoDisplay, block);
		position: absolute;
		left: 2.25rem;
		top: 2.25rem;
		width: 7.5rem;
	}

	.center {
		width: 80%;
		.title {
			margin: 5rem 0 1.5rem;
			max-width: 100%;
			font-size: 2.75rem;
			font-weight: 700;
			text-align: center;
			text-wrap: wrap;

			@media (max-width: $breakpoint-width-lg) {
				margin-top: 6rem;
			}

			@media (max-width: $breakpoint-width-md) {
				font-size: 1.625rem;
			}
		}

		.description {
			text-align: center;
			font-size: 1.125rem;
			line-height: 1.625rem;
			padding: 0 1rem;

			@media (max-width: $breakpoint-width-md) {
				display: none;
			}
		}

		.tileWrapper {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(3, minmax(10rem, 1fr));
			gap: 5rem;
			align-items: stretch;
			margin: 5.938rem auto 1.875rem auto;

			@media (max-width: $breakpoint-width-lg) {
				margin-top: 3rem;
				grid-template-columns: 1fr;
				justify-items: center;
				gap: 1.5rem;
			}

			@media (max-width: $breakpoint-width-sm) {
				margin-top: 2rem;
			}

			.tile {
				display: flex;
				align-items: center;
				justify-content: center;
				justify-self: center;
				width: 100%;
				background-color: var(--neo-color-web-app-component-card-background-default);
				text-align: center;
				padding: 3rem;
				border-radius: 0.5rem;
				border: 1px solid var(--neo-color-global-border-neutral-soft-default);
				box-shadow: 0 0 0 var(--neo-color-global-shadow-default);
				cursor: pointer;
				transition: all 0.25s cubic-bezier(0.83, 0.16, 0.38, 0.9) 0s;
				transform: translate(0, 0);
				max-width: 30rem;

				&:hover {
					box-shadow: 0 6px 8px var(--neo-color-global-shadow-default);
					transform: translate(0, -6.5px);
					transition: all 0.2s cubic-bezier(0.83, 0.16, 0.38, 0.9) 0s;
				}

				&:focus-visible {
					box-shadow: var(--neo-color-global-border-static-focus) 0px 0px 0px 3px inset;
				}

				img {
					width: 100%;
					max-width: 21rem;
				}

				p {
					margin: 0;
					font-size: 1rem;

					strong {
						font-size: 2rem;
						width: 100%;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
