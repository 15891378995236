@import 'theme';

.menuItem {
	color: var(--neo-color-global-content-neutral-intense);
	cursor: pointer;
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	transition: background-color 200ms ease;

	> .content {
		@include focussable;

		height: 100%;
		width: 100%;
		padding: 0.75rem 1rem;
		display: inline-block;
		text-align: left;
		background-color: var(--neo-color-global-background-static-transparent);

		@media (pointer: fine) {
			padding: 0.5rem 1rem;
		}

		color: var(--neo-color-global-content-neutral-intense);
		text-decoration: none;

		&:hover {
			background-color: var(--neo-color-global-background-neutral-moderate-hover);
		}

		&:active {
			background-color: var(--neo-color-global-background-neutral-moderate-active);
		}
	}

	&.separateBefore:not(:first-child) {
		border-top: 1px solid var(--neo-color-global-border-neutral-moderate-default);
	}

	&.separateAfter:not(:last-child) {
		border-bottom: 1px solid var(--neo-color-global-border-neutral-moderate-default);
	}
}
