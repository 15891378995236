@import 'theme';

.cancelButton {
	color: var(--neo-color-global-content-critical-on-intense);
	font-weight: 700;
	cursor: pointer;
	text-decoration: underline;
	margin-left: 1.25rem;
	background: none !important;
	border: none;
	:hover {
		text-decoration: underline;
	}

	@include focussable;
}
