@import 'theme';

.container {
	.revealComponent {
		font-family: 'PX-Grotesk-Mono', 'Monaco', 'Lucida Console', monospace;
		font-variant-numeric: slashed-zero;
		font-size: 0.875rem;

		img {
			cursor: pointer;
			padding: 0;
		}
	}
	.public {
		display: inline-flex;
		align-items: center;
	}

	.secret {
		display: none;
	}

	.revealButton {
		background: none;
		margin: 0;
		padding: 0px;
		align-items: center;
		display: inline-flex;

		@include focussable;
	}

	.revealed {
		.public {
			display: none;
		}

		.secret {
			display: inline-flex;
			align-items: center;
		}
	}
}

.text {
	padding-right: 4px;
}
