@import 'theme';

$event-padding-top: 1rem;
$event-padding-bottom: 1rem;

$bg-unread: var(--neo-color-web-app-component-eventlist-event-background-open-unread);
$bg-read: var(--neo-color-web-app-component-eventlist-event-background-open-read);
$bg-hover: var(--neo-color-web-app-component-eventlist-event-background-open-hover);
$bg-open: var(--neo-color-web-app-component-eventlist-event-surface);
$bg-selected: var(--neo-color-web-app-component-eventlist-event-background-open-selected);

.event {
	display: grid;
	grid-template-columns: 3rem 1fr 3rem;
	grid-gap: 0.125rem;
	background-color: $bg-unread;
	border-bottom: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
	box-shadow: 0.5rem 0 inset var(--neo-color-global-border-info-intense-default);
	font-weight: 700;
	// is needed to align text with star and checkbox
	line-height: 1.0625rem;

	@media (min-width: $breakpoint-width-md) {
		grid-gap: 0.5rem;
	}

	&:first-of-type {
		border-top: 0.0625rem solid var(--neo-color-global-border-neutral-moderate-default);
	}

	&:hover {
		background-color: $bg-hover;
	}

	&.read {
		background-color: $bg-read;
		font-weight: 400;
		box-shadow: none;

		&:hover {
			background-color: $bg-hover;
		}
	}

	&.open {
		background-color: $bg-open;
		color: var(--neo-color-global-content-primary-intense);

		&:hover {
			background-color: $bg-open;
		}
	}
}

.event > div:not(.checkbox) {
	padding-top: 0;
	padding-bottom: 0.125rem;

	@media (min-width: $breakpoint-width-md) {
		padding-top: $event-padding-top;
		padding-bottom: $event-padding-bottom;
	}
}

.event.selected {
	background-color: $bg-selected;

	.detail,
	&.open {
		background-color: $bg-selected;
	}
	&:hover {
		background-color: $bg-selected;
	}
}

.event .star {
	grid-column-start: 1;
	text-align: center;
	background: transparent;
	height: fit-content;

	padding-top: $event-padding-top;
	padding-bottom: $event-padding-bottom;

	@include focussable;

	img {
		width: 1rem;
	}
}

.event .grid {
	grid-column-start: 2;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: 0.125rem;
	user-select: text;
	cursor: pointer;
	flex-grow: 1;

	@media (min-width: $breakpoint-width-md) {
		grid-gap: 0.5rem;
		grid-template-columns: 5.625rem minmax(8.75rem, 1fr) 2fr 2fr 5rem 8.75rem;
		padding-top: $event-padding-top;
		padding-bottom: $event-padding-bottom;
	}

	.wrapper {
		grid-column-start: 1;
		grid-column-end: span 2;
		display: flex;
		overflow: hidden;

		.type {
			display: inline-block;
			flex-shrink: 0;
			padding-top: $event-padding-top;
			width: auto;

			@media (min-width: $breakpoint-width-md) {
				padding-top: 0;
				width: 6.25rem;
			}
		}

		.alias {
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			padding-top: $event-padding-top;

			&::before {
				content: '\00a0';
			}

			@media (min-width: $breakpoint-width-md) {
				padding-top: 0;

				&::before {
					content: none;
				}
			}
		}
	}

	.labels {
		font-weight: normal;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

		grid-column-end: span 2;
		grid-column-start: 1;
		padding-top: 0.25rem;

		@media (min-width: $breakpoint-width-md) {
			grid-column-start: 3;
			grid-column-end: auto;
			padding-top: 0;
		}

		.systemLabel {
			margin: 0.125rem 0.25rem 0.125rem 0;
		}
	}

	.note {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding-right: 1rem;

		@media (max-width: $breakpoint-width-md) {
			grid-column-start: 1;
			grid-column-end: span 2;
		}
	}

	.date {
		text-align: right;
		grid-column-start: 1;
		grid-row-start: auto;
		padding-bottom: $event-padding-bottom;
		padding-top: 0.5rem;

		@media (min-width: $breakpoint-width-md) {
			grid-column-start: 6;
			grid-row-start: 1;
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	.length {
		text-align: right;
		grid-row-start: auto;
		padding-bottom: $event-padding-bottom;
		padding-top: 0.5rem;

		@media (min-width: $breakpoint-width-md) {
			grid-row-start: 1;
			grid-column-start: 5;
			padding-bottom: 0;
			padding-top: 0;
		}
	}
}

.event .checkbox {
	grid-column-start: 3;
	padding: 0.25rem 0 0 0.75rem;
	align-self: start;
}

.event .detail {
	grid-row-start: 2;
	grid-column-end: span 3;
	background: $bg-open;
	margin: -0.5rem 1rem 1rem 1rem;
	font-weight: normal;
	display: flex;
	padding: 1rem 2.125rem;
	flex-direction: column;
	border-radius: 0.25rem;
	@media (min-width: $breakpoint-width-md) {
		padding: 1.5rem 2.625rem;
		flex-direction: row;
	}

	&[hidden] {
		display: none;
	}

	.detailContent {
		flex-grow: 1;

		.info {
			margin: 0.5em 0;
			font-size: 0.875rem;
		}

		p {
			margin-top: 0;
			margin-bottom: 1rem;
			line-height: 1.2;
		}
	}

	.noteArea {
		max-width: 43.75rem;
		margin-top: 2em;
		margin-bottom: 2em;

		@media (min-width: $breakpoint-width-md) {
			margin-bottom: 0;
		}

		.noteWarning {
			display: inline-block;
			min-width: max-content;
		}

		label {
			margin: 0.5em 0;
			font-size: 0.875rem;
		}

		textarea {
			resize: none;
			padding: 0.5rem;
			border-radius: 0.125rem;
			width: 100%;
			margin: 0.5em 0;
			background: var(--neo-color-global-component-input-background-default);
			color: var(--neo-color-global-content-neutral-intense);
			box-shadow: var(--neo-color-global-border-neutral-moderate-default) 0 0 0 1px inset;

			&:hover {
				box-shadow: var(--neo-color-global-border-neutral-moderate-hover) 0 0 0 1px inset;
			}

			&:focus {
				box-shadow: var(--neo-color-global-border-primary-moderate-active) 0 0 0 1px inset;
			}
		}

		textarea::placeholder {
			color: var(--neo-color-global-content-neutral-moderate);
			font-style: italic;
			opacity: 1;
		}

		button {
			margin-right: 1rem;
			margin-bottom: 0.5rem;

			@media (min-width: $breakpoint-width-md) {
				margin-bottom: 0;
			}
		}
	}

	.detailLinks {
		display: flex;
		flex-grow: 1;
		flex-shrink: 0;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: row;

		@media (min-width: $breakpoint-width-md) {
			flex-direction: column;
		}
	}
}

.eventlistButton {
	margin: 0;
	padding: 0;
	text-align: left;
	background: transparent;
	display: flex;
}

.blurred {
	filter: blur(0.25rem);
}
