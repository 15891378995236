@import 'theme';

.controlBar {
	background-color: var(--neo-color-web-app-surface-app);
	position: sticky;
	top: 0;
	z-index: $layer-controlbar;
	padding: 1rem 0;
}

.eventList {
	font-size: 0.875rem;
}

.list {
	display: flex;

	@media all and (-ms-high-contrast: none) {
		display: block;
	}

	flex-direction: column;
	overflow: auto;

	flex-shrink: 1;
	flex-grow: 1;
}

.list > ol {
	margin: 0;
	padding: 0;
	flex-grow: 1;
}

.illustrationContainer {
	width: 100%;
}

.illustration {
	width: 20rem;
}

.pandaEmptyState {
	margin: 4rem auto;
}

.pageControl {
	display: flex;
	flex-shrink: 0;
	// Avoid intercom bubble
	padding: 24px 8px 88px 8px;

	@media (min-width: $breakpoint-width-sm) {
		padding: 24px 8px;
	}

	div {
		flex: 1;
	}

	.export,
	.filler {
		flex: 0;

		@media (min-width: $breakpoint-width-sm) {
			flex: 1;
		}
	}

	.exportIconButton {
		display: none;
	}

	.center {
		flex: 3;
		display: flex;
		justify-content: center;
	}

	.exportTextButton {
		display: none;

		@media (min-width: $breakpoint-width-md) {
			display: block;
		}
	}

	.exportIconButton {
		display: block;

		@media (min-width: $breakpoint-width-md) {
			display: none;
		}
	}
}

.pageSize {
	margin-left: 8px;
	display: none;
	white-space: nowrap;

	@media (min-width: $breakpoint-width-md) {
		display: flex;
	}
}

.buttonWrapper {
	margin-top: 24px;
}
