@import 'theme';

.spinner {
	height: 50px;
	width: 50px;
	display: block;
	margin: 16px auto;
}

.videoIdentContent {
	line-height: 1.5em;
	text-align: center;
}
