@import 'theme';

.userDropDown {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
	font-family: $font-px-grotesk;
	user-select: none;

	button {
		border: none;
		outline: inherit;
		text-align: left;
		background: none;
		padding: 0;
		display: flex;
		align-items: center;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		&:not(.withLogo) > svg > g > circle,
		&:not(.withLogo) > svg > g > foreignObject > img {
			transition: all 150ms ease-in-out;
			pointer-events: none;
		}

		&:not(.withLogo):hover > svg > g > circle,
		&:not(.withLogo):hover > svg > g > foreignObject > img {
			filter: brightness(0.949019608);
		}

		&:not(.withLogo):active > svg > g > circle,
		&:not(.withLogo):active > svg > g > foreignObject > img {
			filter: brightness(0.921568627);
		}

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus);
		}

		&.userDropDownButton {
			border-radius: 50%;
		}

		@media (max-width: calc($breakpoint-width-md - 1px)) {
			&.withLogo {
				& > img {
					display: none;
				}
			}
		}

		@media (min-width: $breakpoint-width-md) {
			&.withLogo {
				display: inline-flex;
				padding: 0.25rem 0.5rem;
				align-items: center;
				gap: 0.5rem;
				border-radius: 0.25rem;
				box-shadow: inset 0 0 0 0.0625rem var(--neo-color-global-border-neutral-soft-default);
				background: var(--neo-color-web-app-component-user-dropdown-button-with-logo-background);

				& > img {
					max-width: 8rem;
					max-height: 1.5rem;
					pointer-events: none;
				}

				&:hover {
					filter: brightness(0.949019608);
					box-shadow: inset 0 0 0 0.0625rem var(--neo-color-global-border-neutral-soft-hover);
				}

				&:active {
					filter: brightness(0.921568627);
					box-shadow: inset 0 0 0 0.0625rem var(--neo-color-global-border-neutral-soft-active);
				}

				&:focus-visible {
					box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
					border-color: var(--neo-color-global-border-static-focus);
				}
			}
		}
	}

	.currentUser {
		display: flex;
		gap: 0.5rem;
		padding: 0.75rem 0.75rem 0.5rem 0.75rem;
		align-items: center;

		.currentUserRightContainer {
			display: flex;
			flex-direction: column;
			user-select: text;
		}

		.customerName {
			color: var(--neo-color-global-content-neutral-intense);
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 700;
			line-height: 1rem;

			max-width: 8.5rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.secondaryText {
			color: var(--neo-color-global-content-neutral-moderate);
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1rem;
		}
	}

	.logout {
		color: var(--neo-color-global-content-critical-moderate);

		button {
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			align-items: center;
			border-radius: 0 0 0.5rem 0.5rem;

			padding: 0.75rem;
			width: 100%;

			&:hover {
				background: var(--neo-color-global-background-critical-soft-hover);
			}

			&:active {
				background: var(--neo-color-global-background-critical-soft-active);
			}

			&:focus-visible {
				box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			}
		}
	}
}
