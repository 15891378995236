@import 'theme';

.spinner {
	width: 3rem;
}

.container {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.5rem;
	padding: 1rem 1rem 1rem 0;
	overflow: hidden;

	&.cancelled {
		--description-color: var(--neo-color-global-content-warning-moderate);
	}

	&.activeBooking {
		--description-color: var(--neo-color-global-content-info-moderate);
	}

	img {
		width: 3rem;
	}

	.withContextMenuAndSubline {
		display: flex;
		gap: 0.5rem;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		.headline {
			display: flex;
			font-weight: 700;
			font-size: 1rem;
			line-height: 1.25rem;
			align-items: center;
			gap: 0.25rem;
			white-space: nowrap;
		}

		.subline {
			margin-top: 0.25rem;
			font-size: 0.75rem;

			.description {
				color: var(--description-color, var(--neo-color-global-content-neutral-moderate));
			}
		}
	}
}
