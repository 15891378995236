@import 'theme';

.spinnerWrapper {
	min-height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
}

.spinner {
	width: 50px;
	height: 50px;
}

.filterlist {
	list-style: none;
	padding: 0;
	margin: 12px 16px;
}

.twoColumns .filterlist {
	margin: 0;

	li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		width: 100%;
	}
}
