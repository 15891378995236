@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 41;
	}
	50% {
		stroke-dashoffset: 10;
		transform: rotate(180deg);
	}
	100% {
		stroke-dashoffset: 41;
		transform: rotate(360deg);
	}
}

.spinner {
	display: flex !important;
	transform: rotate(0deg);
	animation: rotator 1.6s linear infinite;
	width: 1rem;
	height: 1rem;
	animation-fill-mode: forwards;
}

.path {
	stroke-dasharray: 42;
	stroke-dashoffset: 41;
	transform-origin: center;
	animation: dash 1.6s ease-in-out infinite;
	fill: none;
	stroke: currentColor;
	stroke-width: 2;
	stroke-linecap: round;
}
