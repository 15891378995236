@import 'theme';

.icon {
	& > svg > g {
		fill: none;
		stroke-width: 3;
		stroke-linecap: round;
	}
}

.default {
	& > svg > g {
		stroke: var(--neo-color-global-content-neutral-moderate);
	}
}

.active {
	& > svg > g {
		stroke: var(--neo-color-global-content-primary-moderate);
	}
}
