@import 'theme';

.selected {
	background-color: var(--neo-color-global-background-neutral-moderate-hover);
}

.phonenumberSuggestion {
	@include focussable;

	padding: 1rem;
	width: 100%;

	@media (min-width: $breakpoint-width-lg) {
		padding: 0.5rem;
	}

	@media (min-width: $breakpoint-width-2xl) {
		padding: 0.5rem 1rem;
	}
}

.number,
.name {
	width: 50%;
}
