.content {
	text-align: center;
	line-height: 1.5;
}

.buttonContainer {
	flex-direction: column;
	display: flex;
	align-items: center;

	& > :nth-child(2) {
		margin-top: 16px;
	}
}
