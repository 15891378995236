/* stylelint-disable unit-disallowed-list -- DialogContent is deprecated so we don't want to fix this. */
@import 'theme';

.container {
	font-family: $font-px-grotesk;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 3rem;
	width: 100vw;
	height: 100vh;
	min-height: 100%;
	background-color: var(--neo-color-global-surface-dialog);

	@media (min-width: $breakpoint-width-md) {
		width: auto;
		height: auto;
		min-height: 0;
		max-height: 90vh;
		padding-bottom: 2.5rem;
	}
}

.head {
	font-size: 1.5rem;
	text-align: center;
	margin: 1rem 0 1.5rem;
	font-weight: 700;
	color: var(--neo-color-global-content-neutral-intense);
}

.headerContainer {
	text-align: center;
	padding-left: 3rem;
	padding-right: 3rem;
	padding-bottom: 0;
	width: 100%;
}

.contentContainer {
	padding: 0.5rem 1rem;
	max-width: 20rem;
	margin: auto;
	color: var(--neo-color-global-content-neutral-intense);
}

.contentContainer.fullWidth {
	padding: 0;
}

.iconContainer {
	margin: 0 auto;
	height: 4.25rem;
	width: 4.25rem;
}

.illustrationContainer {
	margin: 0 auto;
	max-height: 8rem;

	img {
		max-height: 8rem;
	}
}

.centerContainer {
	width: 100%;
	max-width: 20rem;
	margin: auto;
	padding: 0 1rem 1.5rem;
	display: flex;
	flex-direction: column;

	@media (min-width: $breakpoint-width-md) {
		padding-bottom: 0;
	}
}

.hintText {
	margin: 0.875rem auto 1.25rem;
	padding: 0 2.8125rem;
	line-height: 1.375rem;
	text-align: center;
	font-size: 0.875rem;
	font-weight: 400;
	color: var(--neo-color-global-content-neutral-intense);

	@media (min-width: $breakpoint-width-md) {
		margin-bottom: 1rem;
	}
}
