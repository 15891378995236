@import 'theme';

.bold {
	font-weight: 700;
}

.player {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	align-items: center;
	max-width: 43.75rem;
}

.player > :first-child {
	flex-grow: 1;
}

.transcription {
	margin-top: 1rem;
}

.transcriptionHead {
	margin: 0.5em 0;
	font-size: 0.875rem;
}

.actionButtons {
	display: flex;
	flex-wrap: wrap;

	button {
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		white-space: nowrap;
	}

	.click2PlayButton {
		display: none;

		@media (min-width: $breakpoint-width-md) {
			display: block;
		}
	}
}
